<template>
  <layout-private :customer="customer" :pageTitle="'Admin'">
    <div class="page">

      <div v-if="!custom_claims || (custom_claims && custom_claims.adminLevel == 'mobileOnly')">
        <p>Oops, you don't have admin permissions.</p>
      </div>

      <div v-if="custom_claims && custom_claims.adminLevel !== 'mobileOnly'">
        <h4>To use Admin features, please log in to Smiley on a computer. Here's some of what you can do:</h4>

        <ul>
          <li>Add &amp; manage locations</li>
          <li>Add &amp; manage team members</li>
          <li>Connect OpenDental</li>
          <li>Manage your Smiley subscription</li>
        </ul>

        <p v-if="environment == 'production'">Visit <strong><a href="https://admin.hey-smiley.com" target="_blank">https://admin.hey-smiley.com</a></strong> to continue!</p>
        <p v-if="environment == 'staging' || environment == 'development'">Visit <strong><a href="https://smiley-staging.web.app" target="_blank">https://smiley-staging.web.app</a></strong> to continue!</p>
      </div>

    </div>
  </layout-private>
</template>

<script>
import Vue from 'vue'
import LayoutPrivate from '../components/LayoutPrivate.vue'
import {mapGetters} from 'vuex'
import ListErrors from '../components/ListErrors.vue'

export default {
  props: ['customer'],
  components: {
    LayoutPrivate, ListErrors
  },
  data () {
    return {
      errors: [],
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims', 'custom_claims']),
    environment() {
      if(process.env.VUE_APP_ENV === "staging") {
        return "staging";
      }
      if(process.env.NODE_ENV === "production") {
        return "production";
      }
      if(process.env.NODE_ENV === "development" || process.env.VUE_APP_ENV === "test") {
        return "development";
      }
      return null;
    },
  },
  watch: {
    user (auth) {

    }
  },
  methods: {

  },
  mounted () {

  },
}
</script>

<style lang="less" scoped>
.page {
  padding-top: 30px;
}
</style>
