<template lang="html">
  <div v-if="message" class="current-message message-award" :class="message.award_type">

    <div class="overflow-y-scroll">
      <div class="icon-lg text-center">

        <div class="bump-overlay">
          <span v-for="i in bumpIncrement" v-if="bumpIncrement" class="bubble bubble-anim text-center"><img src="../assets/img/icon-clap-outline.svg" alt=""></span>
          <div class="bump d-flex align-items-center">
            <span v-if="bumpTimeout" class="bump-spinner"><i class="far fa-fw fa-pulse fa-spinner"></i></span>
            <span class="icon-plus-round d-flex align-items-center justify-content-center"><i class="far fa-plus"></i></span>
            <button class="btn-bump" @click="bumpMessage">
              <span class="post-badge-round post-badge-clap">
                <img src="../assets/img/icon-clap-outline.svg" alt="" width="32px" height="32px">
              </span>
            </button>
            <span v-if="message.bump_count" class="bump-count">{{ message.bump_count + bumpIncrement + savedBumps }}</span>
            <span v-if="!message.bump_count" class="bump-count">{{ bumpIncrement + savedBumps }}</span>
          </div>
        </div> <!-- /.bump-overlay -->
      </div> <!-- /.icon-lg-->
      
      <div class="col-12">
        <h2 v-if="message.award_type == 'weekly_practice_health'">Weekly <strong>Practice Health</strong> Leader</h2>
        <h2 v-if="message.award_type == 'weekly_team_health'">Weekly <strong>Team Health</strong> Leader</h2>
        <h2 v-if="message.award_type == 'weekly_patient_health'">Weekly <strong>Patient Health</strong> Leader</h2>
        <h1 v-if="winnerName && minXVal != winnerMetricValue">{{ winnerName }}</h1>
        <h1 v-if="minXVal == winnerMetricValue">It's a tie!</h1>

        <hr>

        <p v-if="minXVal == winnerMetricValue" class="lead">Good job, everyone! Keep it up and check back next week.</p>
        
        <!-- display only if range of values -->
        <div v-if="minXVal != winnerMetricValue">
          <!-- weekly practice health -->
          <p class="lead" v-if="message.award_type == 'weekly_practice_health'">
            {{ winnerName }} is <strong>
              <span v-if="practiceHealthTitle">{{ practiceHealthTitle }}!</span>
              <span v-if="!practiceHealthTitle">working hard!</span>
            </strong>
          </p>
          <p class="lead" v-if="message.award_type == 'weekly_practice_health' && winnerMetricValue && winnerName">Last week, net production was {{ winnerMetricValue | limitDecimals() }}% <span v-if="Math.sign(winnerMetricValue) == 1">over</span><span v-if="Math.sign(winnerMetricValue) == -1">under</span> our goal. Great job, {{ winnerName }} team!</p>
          
          <!-- weekly team health -->
          <p class="lead" v-if="message.award_type == 'weekly_team_health'">{{ winnerName }} is <strong>a dream team!</strong></p>
          <p class="lead" v-if="message.award_type == 'weekly_team_health' && winnerMetricValue && winnerName">Last week, {{ winnerMetricValue | limitDecimals() }}% of team members were caught up on Smiley messages. Great job, {{ winnerName }} team!</p>

          <!-- weekly patient health -->
          <p class="lead" v-if="message.award_type == 'weekly_patient_health'">
            {{ winnerName }} is <strong>making a difference!</strong></p>
          <p class="lead" v-if="message.award_type == 'weekly_patient_health' && winnerMetricValue && winnerName">Last week, Care Progress Rate was {{ winnerMetricValue | limitDecimals() }}%. Great job, {{ winnerName }} team!</p>
        </div>
        

      </div> <!-- /.col-12 -->
      <!-- stats chart for mixed pos & neg values-->
      <div v-if="sortedData!=null && minXVal!=null && winnerMetricValue!=null && minXVal < 0 && winnerMetricValue >= 0" class="chart-container chart-container-mixed">
          <div class="chart-row" v-for="item in sortedData" :key="item.id">
            <p>{{ item.name }}: <span v-if="Math.sign(item.metric_value) == 1">+</span>{{ item.metric_value | limitDecimals(2) }}%</p>
            <p class="sm">{{ item.subtitle }}</p>
            <div class="bar-container">
              <div class="y-line y-line-center"></div>
              <div :style="{width: calcWidth(item.metric_value) + '%', 'margin-left': item.metric_value>0 ? '50%' : -calcWidth(item.metric_value) + '%'}" class="bar" :class="[calcColor(item.metric_value), item.metric_value>0 ? 'bar-pos' : 'bar-neg']"></div>
            </div>
          </div>
      </div>
      <!-- stats chart for all neg values-->
      <div v-if="sortedData!=null && minXVal!=null && winnerMetricValue!=null && minXVal < 0 && winnerMetricValue < 0" class="chart-container chart-container-neg">
          <div class="chart-row" v-for="item in sortedData" :key="item.id">
            <p>{{ item.name }}: <span v-if="Math.sign(item.metric_value) == 1">+</span>{{ item.metric_value | limitDecimals(2) }}%</p>
            <p class="sm">{{ item.subtitle }}</p>
            <div class="bar-container">
              <div class="y-line y-line-right"></div>
              <div :style="{width: calcWidth(item.metric_value) + '%'}" class="bar bar-right" :class="[calcColor(item.metric_value)]"></div>
            </div>
          </div>
      </div>
      <!-- stats chart for all pos values-->
      <div v-if="sortedData!=null && minXVal!=null && winnerMetricValue!=null && minXVal >= 0 && winnerMetricValue >= 0" class="chart-container chart-container-pos">
          <div class="chart-row" v-for="item in sortedData" :key="item.id">
            <p>{{ item.name }}: <span v-if="Math.sign(item.metric_value) == 1">+</span>{{ item.metric_value | limitDecimals(2) }}%</p>
            <p class="sm">{{ item.subtitle }}</p>
            <div class="bar-container">
              <div class="y-line y-line-left"></div>
              <div :style="{width: calcWidth(item.metric_value) + '%'}" class="bar" :class="[calcColor(item.metric_value)]"></div>
            </div>
          </div>
      </div>
    </div> <!-- /.overflow-y-scroll-->
  </div> <!-- /.current-message -->
</template>

<script>
import Vue from 'vue'
import Firebase from 'firebase/compat/app'
import store from '../store'
import {mapGetters} from 'vuex'

export default {
  props: ['message'],
  data () {
    return {
      bumpIncrement: 0,
      bumpTimeout: null,
      savedBumps: 0,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),
    sortedData() {
      if(this.message.data && this.message.data.length) {
        return this.message.data.toSorted((a,b) => b.metric_value - a.metric_value);
      }
      return null;
    },
    winnerObject() {
      if(this.message && this.message.data && this.message.data.length) {
        // combine all values into an array
        const values = this.message.data.reduce(
          (combined,next) => {
            // console.log('next',next)
            combined.push(Number(next.metric_value));
            // console.log('combined',combined)
            return combined;
          },
          // set initial value different from [0], empty array
          []
        );
        // return smallest value in array
        // console.log('all data values',values);
        // console.log('min',Math.min(...values))
        const winnerVal =  Math.max(...values);
        return this.message.data.find(i => i.metric_value == winnerVal);
      }
    },
    winnerName() {
      if(this.winnerObject && this.winnerObject.name) {
        return this.winnerObject.name;
      }
      return null;
    },
    winnerMetricValue() {
      if(this.winnerObject && this.winnerObject.metric_value) {
        return this.winnerObject.metric_value;
      }
      return null;
    },
    totalScale() {
      if(this.winnerObject) {
        return this.message.data.reduce((partialSum, next) => partialSum + Math.abs(next.metric_value), 0);
      }
      return null;
    },
    minXVal() {
      if(this.winnerObject) {
        // combine all values into an array
        const values = this.message.data.reduce(
          (combined,next) => {
            // console.log('next',next)
            combined.push(Number(next.metric_value));
            // console.log('combined',combined)
            return combined;
          },
          // set initial value different from [0], empty array
          []
        );
        // return smallest value in array
        // console.log('all data values',values);
        // console.log('min',Math.min(...values))
        return Math.min(...values);
      }
      return null;
    },
    practiceHealthTitle() {
      if(this.message.award_type == 'weekly_practice_health' && this.winnerMetricValue) {
        if(this.winnerMetricValue < -10) {
          return 'working hard';
        }
        if(this.winnerMetricValue <= 0) {
          return 'Delivering The Way';
        }
        if(this.winnerMetricValue <= 10) {
          return 'Growing The Way';
        }
        if(this.winnerMetricValue < 20) {
          return 'Leading The Way';
        }
        if(this.winnerMetricValue >= 20) {
          return 'Scaling The Way';
        }
      }
      return null;
    },
  },
  methods: {
    calcWidth(value) {
      if(this.winnerMetricValue==null || value==null || this.minXVal==null) {
        return;
      }
      let perc;
      // MIXED: if min value is a negative number & max is positive
        // adjust scale to start from it instead of zero
      if(this.minXVal < 0 && this.winnerMetricValue >= 0) {
        // use half 100% scale since it's split
        perc =  Math.abs(value) / this.totalScale *100/2;
        if(perc >= 50) {
          perc = 50;
        }
      }
      // ALL NEGATIVE: if both min and max are negative
      if(this.minXVal < 0 && this.winnerMetricValue < 0) {
        // max when all negative
        if(value < 0 && value == this.winnerMetricValue) {
          perc = Math.abs(value) / (Math.abs(this.winnerMetricValue) + Math.abs(this.minXVal)) * 100;
        }
        // mid value when all negative
        if(value < 0 && value > this.minXVal) {
          perc = Math.abs(value) / (Math.abs(this.winnerMetricValue) + Math.abs(this.minXVal)) * 100;
        }
        // min value when all negative = 100
        if(value < 0 && value == this.minXVal) {
          perc = 100;
        }
      }
      // ALL POSITIVE: if all positive numbers
      if(this.minXVal >= 0 && this.winnerMetricValue >= 0) {
        perc = Math.abs(value * 100 / this.winnerMetricValue);
      }

      if(perc == null || perc == undefined) {
        return 0;
      } else if(perc > 100) {
        return 100;
      } else if(perc == 0) {
        return 1;
      } else {
        return perc;
      }
    },
    calcColor(value) {
      if(value) {
        if(this.message.award_type == 'weekly_practice_health') {
          // use colors for living, growing, leading, scaling the way etc
          // red - 0-89.99 // < -10%
          // orange - 90-99.99 // < 0%
          // blue - 100-109.99 // < 10%
          // green -  110-119.99 // < 20%
          // purple - 120+ // >= 20%
          if(value < -10) {
            return 'red';
          }
          if(value <= 0) {
            return 'orange';
          }
          if(value <= 10) {
            return 'blue';
          }
          if(value < 20) {
            return 'green';
          }
          if(value >= 20) {
            return 'purple';
          }
        }
        if(this.message.award_type == 'weekly_team_health') {
          if(value <= 10) {
            return 'red';
          }
          if(value <= 30) {
            return 'orange';
          }
          if(value <= 50) {
            return 'blue';
          }
          if(value < 75) {
            return 'green';
          }
          if(value >= 75) {
            return 'purple';
          }
        }
        if(this.message.award_type == 'weekly_patient_health') {
          if(value <= 30) {
            return 'red';
          }
          if(value <= 40) {
            return 'orange';
          }
          if(value <= 50) {
            return 'yellow';
          }
          if(value <= 60) {
            return 'blue';
          }
          if(value > 60) {
            return 'green';
          }
        }
      }
      return null;
    },
    markRead() {
      if(!this.user || !this.user.uid || !this.custom_claims || !this.custom_claims.customerId || !this.message) {
        return;
      }
      // console.log('call markRead')
      let read_by_users = [];
      if(this.message.read_by_users && this.message.read_by_users.length) {
        // if the user already read it, exit
        if(this.message.read_by_users.includes(this.user.uid)) {
          // console.log('already read')
          return;
        }
        // if array exists, store it
        read_by_users = this.message.read_by_users;
      }
      // add this user
      read_by_users.push(this.user.uid)
      // add this user to message's read_by_users array
      Vue.firestore.doc(`customers/${this.custom_claims.customerId}/messages/${this.message.id}`)
        .update({
          read_by_users: read_by_users,
        })
        .then(() => {
          // console.log('message marked as read')
        })
        .catch(err => console.log(err));
    },
    bumpMessage() {
      if(!this.user || !this.user.uid || !this.custom_claims || !this.custom_claims.customerId || !this.message) {
        return;
      }
      // console.log('call bumpMessage')
      this.bumpIncrement++;
      // run timeout on window so we can clear it in separate function
      this.bumpTimeout = window.setTimeout(() => this.saveBumps(),1000);

      // award points for bumping once per day
      const dateCode = new Date().toISOString().slice(0, 10).replaceAll('-', '');
      if(!this.user_props.points_bumped_as_of || this.user_props.points_bumped_as_of !== dateCode) {
        Vue.firestore.collection('users')
          .doc(this.user.uid)
          .update({
            // do not use FieldValue.increment, instead do math manually
            // points: Firebase.firestore.FieldValue.increment(10),
            points: this.user_props.points ? this.user_props.points+5 : 5,
            // save datecode to prevent awarding more than once a day
            points_bumped_as_of: dateCode,
          });
      }
    },
    saveBumps() {
      window.clearTimeout(this.bumpTimeout);
      // save a copy of current increment in case it changes during saving
      const bCount = this.bumpIncrement;
      // store before saving in case they click again
      this.savedBumps += bCount;
      this.bumpIncrement = 0;
      // save to db
      Vue.firestore.doc(`customers/${this.custom_claims.customerId}/messages/${this.message.id}`)
        .update({
          bump_count: Firebase.firestore.FieldValue.increment(bCount),
        })
        .then(() => {
          // console.log('message bumps saved')
          this.bumpTimeout = null; // hide loading indicator
        })
        .catch(err => {
          console.log(err);
          this.bumpTimeout = null;
          this.savedBumps -= bCount;
        });
    }
  },
  mounted() {
    this.markRead();
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.chart-container {
  background-color: @light-color;
  padding: 30px;
}
.chart-row {
  margin-bottom: 20px;

  p {
    margin: 0;
    font-size: 20px;
    line-height: 24px;
  }
  p.sm {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
  }
}
.bar-container {
  width: 100%;
  border-bottom: 1px solid @dark-color;
  position: relative;
  .bar {
    border: 1px solid @dark-color;
    border-bottom: none;
    height: 15px;
    line-height: 15px;
  }
  // if max value is negative, bars stack right instead to show negatives
  .bar-pos {
    margin-left: 50%;
    border-left: none;
  }
  .bar-neg {
    position: relative;
    left: 50%;
    border-right: none;
  }
  // if all negative values, align all bars right
  .bar-right {
    margin-left: auto;
  }
  .y-line {
    position: absolute;
    bottom: 0;
    height: 20px;
    border-left: 1px solid @dark-color;
  }
  .y-line.y-line-left {
    left: 0;
  }
  .y-line.y-line-right {
    right: 0;
  }
  .y-line.y-line-center {
    left: 50%;
  }
}
// bar colors
// ----------
// .color-swatch-purple,
.purple.bar  {
  background-color: @color-magenta;
}
// .color-swatch-green,
.green.bar  {
  background-color: @color-green;
}
// .color-swatch-blue,
.blue.bar  {
  background-color: @color-blue;
}
// .color-swatch-orange,
.orange.bar  {
  background-color: @color-orange;
}
// .color-swatch-red,
.red.bar {
  background-color: @color-salmon;
}
.yellow.bar {
  background-color: @color-yellow;
}

.message-award .icon-lg {
  position: relative;
  height: 365px;
}
.message-award.weekly_practice_health .icon-lg {
  background: url('../assets/img/bg-award-mountains@2x.png') center -130px no-repeat;
  background-size: 497px 497px;
}
.message-award.weekly_team_health .icon-lg {
  background: url('../assets/img/bg-award-phone@2x.png') center -130px no-repeat;
  background-size: 497px 497px;
}
.message-award.weekly_patient_health .icon-lg {
  background: url('../assets/img/bg-award-toothbrush@2x.png') center -130px no-repeat;
  background-size: 497px 497px;
}

.current-message {
  width: 100%;
  height: 100%;
  overflow-x: visible;
  background-color: @color-blue;
}
.current-message.heads_up {
  background-color: @color-blue;
}
.current-message.high_five {
  background-color: @color-orange;
}
.col-12 {
  background: url('../assets/img/bg-banner-light@2x.png') center top no-repeat;
  background-size: 199px 145px;
  padding: 0 30px;
}
hr {
  margin: 20px 0;
  border-top: 1px solid rgba(255,255,255,0.5);
}
h2 {
  overflow-wrap: break-word;
  text-align: center;
  margin: 0 0 5px;
}
h1 {
  overflow-wrap: break-word;
  text-align: center;
  margin: 0;
}
// for channel name when catchUp==true
.channelName {
  text-align: center;
  margin: 20px 0;
}
p.lead {
  font-size: 22px;
  line-height: 28px;
}
.overflow-y-scroll {
  height: 100%;
  overflow-y: auto;
  overflow-x: visible;
}

// post type badges
  // copied from .btn-round in ActivityView.vue
.post-badge-round {
  width: 44px; // add 4 for borders
  height: 44px;
  line-height: 40px;
  overflow: hidden;
  border-radius: 40px;
  margin-left: 10px;
  text-align: center;
  border: 2px solid @light-color;
  
  img {
    vertical-align: baseline; // browser default
    transition: all 250ms ease-in-out;
  }
}
.post-badge-clap {
  background-color: @color-green;

  img {
    vertical-align: middle; // slightly off v-center but prefer option to bottom
  }
}
// bump message
.bump-overlay {
  position: absolute;
  bottom: 120px;
  left: 245px;
}
.bump {
  position: relative;
  min-width: 65px;
  height: 50px;
}
.icon-plus-round {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: @light-color;
    color: @dark-color;
    font-size: 14px;
    line-height: 14px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
.btn-bump {
  // overrides
  border-radius: 0;
	font-size: 16px;
	line-height: 1em;
	padding: 0;
	text-decoration: none;
	box-shadow: none;
  border: none !important;
  outline: none;
  margin: 0;
  // custom
  background-color: transparent !important;
  text-align: left;
  display: block;
  position: absolute;
  bottom: 3px;
  left: 5px;
  z-index: 1;

  .post-badge-round {
    display: inline-block;
    margin: 0;
  }
}
.bump-count {
  display: block;
  width: 100%;
  text-align: right;
  padding: 1px 3px 3px 40px;
  margin-left: 10px;
  background-color: @light-color;
  font-size: 14px;
  line-height: 14px;
  color: @dark-color;
  border-radius: 3px;
}
.bump-spinner {
  position: absolute;
  left: -14px;
  top: 17px;
  color: @light-color;
  background-color: fadeout(@dark-color,80%);
  border-radius: 50px;
  font-size: 12px;
}
.bubble {
  display: block;
  width: 28px;
  height: 28px;
  border-radius: 50px;
  background-color: @light-color;
  position: absolute;
  top: 0;
  left: 12px;
  z-index: 100;
  opacity: 0;
}
.bubble-anim {
  animation: 500ms 1 growup ease-out;
}
@keyframes growup {
  from {
    display: block;
    opacity: 0;
    top: 0;
    scale: 10%;
  }
  75%{
    opacity: 1;
    top: -40px;
    scale: 100%;
  }
  to {
    opacity: 0;
    top: -45px;
    scale: 10%;
  }
}

</style>
