import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations, firestoreAction } from 'vuexfire'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    user_props: null,
    custom_claims: null,
    updated_service_worker: false,
    current_message: null,
  },
  getters: {
    user: state => state.user,
    user_props: state => state.user_props,
    custom_claims: state => state.custom_claims,
    updated_service_worker: state => state.updated_service_worker,
    current_message: state => state.current_message,
  },
  mutations: {
    ...vuexfireMutations,
    UPDATE_USER (state, user_payload) {
      state.user = user_payload;
    },
    RESET_STATE (state, account_payload) {
      state.user = null;
      state.user_props = null;
      state.custom_claims = null;
    },
    UPDATE_CUSTOM_CLAIMS (state, custom_claims_payload) {
      state.custom_claims = custom_claims_payload;
    },
    UPDATE_SERVICE_WORKER (state, updated_sw_payload) {
      state.updated_service_worker = updated_sw_payload;
    },
    SET_CURRENT_MESSAGE (state, current_message_payload) {
      state.current_message = current_message_payload;
    },
    CLEAR_CURRENT_MESSAGE (state) {
      state.current_message = null;
    },
  },
  actions: {
    updateUser (context, user_payload) {
      context.commit('UPDATE_USER', user_payload)
    },
    resetState (context) {
      context.commit('RESET_STATE')
    },
    updateCustomClaims (context, custom_claims_payload) {
      context.commit('UPDATE_CUSTOM_CLAIMS', custom_claims_payload)
    },
    updatedServiceWorker (context, updated_sw_payload) {
      context.commit('UPDATE_SERVICE_WORKER', updated_sw_payload)
    },
    setCurrentMessage (context, current_message_payload) {
      context.commit('SET_CURRENT_MESSAGE', current_message_payload)
    },
    clearCurrentMessage (context) {
      context.commit('CLEAR_CURRENT_MESSAGE')
    },
    // bind state to firebase using vuexfire
    // Firestore /users collection
    setUserPropsRef: firestoreAction((context, documentRef) => {
      // context contains all original properties like commit, state, etc
      // and adds `bindFirestoreRef` and `unbindFirestoreRef`
      // we return the promise returned by `bindFirestoreRef` that will
      // resolve once data is ready
      return context.bindFirestoreRef('user_props', documentRef)
    }),
  }
})
