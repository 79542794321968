<template lang="html">
  <layout-public class="log-in">
    <div>
      <h1>Log In</h1>

      <p v-if="googleAuthLoading"><i class="far fa-fw fa-pulse fa-spinner"></i> Please wait. Logging in with Google</p>

      <div v-if="!googleAuthLoading">
        <p v-if="!loading"><button class="btn btn-app btn-google" @click="logInWithGoogle"><i class="fab fa-google fa-fw"></i>Log In with Google</button></p>

        <hr class="hr-short" />

        <h4>Log In with Email</h4>

        <input v-model="email" name="email" class="form-control" placeholder="Enter email" type="text" @keyup.enter="logIn"/>
        <input v-model="password" name="password" class="form-control" placeholder="Enter password" type="password" @keyup.enter="logIn"/>

        <list-errors :errors="errors"></list-errors>

        <div class="d-flex flex-row justify-content-between">
          <p><button v-if="!loading" @click.prevent="logIn" class="btn btn-app">Log In<i class="far fa-angle-right fa-fw"></i></button></p>
          <p v-if="loading"><i class="far fa-fw fa-pulse fa-spinner"></i></p>
          <p><router-link to="/reset-password">Forgot password?</router-link></p>
        </div>

        <hr class="hr-dark-color hr-bottom-short" />

        <p>Don't have an account? <router-link to="/how-to-join">Here's how to get one</router-link>.</p>
      </div>
      
    </div>
  </layout-public>
</template>

<script>
import {mapGetters} from 'vuex'
import LayoutPublic from '../components/LayoutPublic.vue'
import ListErrors from '../components/ListErrors.vue'

export default {
  data () {
    return {
      email: '',
      password: '',
      errors: [],
      loading: false,
      googleAuthLoading: false,
    }
  },
  components: {
    LayoutPublic, ListErrors
  },
  computed: {
    ...mapGetters(['user']),
    // get next route from querystring, set in FirebaseApp.js, or default to root
    nextRoute () {
      return this.$route.query.redirect || '/'
    },
    environment() {
      if(process.env.VUE_APP_ENV === "staging") {
        return "staging";
      }
      if(process.env.NODE_ENV === "production") {
        return "production";
      }
      if(process.env.NODE_ENV === "development" || process.env.VUE_APP_ENV === "test") {
        return "development";
      }
      return null;
    },
  },
  watch: {
    //  when signUp completes, go to requested page
    user (auth) {
      if(auth) {
        this.$router.replace(this.nextRoute)
      }
    }
  },
  mounted () {
    // on page refresh, if user, go to requested page
    if(this.user) {
      this.$router.replace(this.nextRoute)
    }
  },
  methods: {
    async logInWithGoogle () {
      // reset errors
      this.errors = [];
      //  attempt logInWithGoogle
      this.googleAuthLoading = true;
      let result = await this.$firebase.logInWithGoogle(this.email, this.password);
      if(result && result.code) {
        this.loading = false;
        this.errors.push(result.code);
        this.googleAuthLoading = false;
      }
    },
    async logIn () {
      // reset errors
      this.errors = [];
      //  attempt logIn
      let result = await this.$firebase.logIn(this.email, this.password);
      // if result doesn't contain user, it's an error
      // console.log(result);
      if(!result.user) {
        if(result.code == "auth/invalid-email") {
          this.errors.push("Oops, that's not a valid email address.")
        }
        else if(result.code == "auth/wrong-password") {
          this.errors.push("Oops, the password or email address is incorrect.")
        }
        else if(result.code == "auth/user-not-found") {
          this.errors.push("Oops, there is no account using that email address.")
        }
        else {
          this.errors.push(result.message);
        }
      }
    }
  },
}
</script>

<style lang="less" scoped>
</style>
