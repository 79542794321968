import { render, staticRenderFns } from "./MessageOnboarding.vue?vue&type=template&id=75b15453&scoped=true&lang=html&"
import script from "./MessageOnboarding.vue?vue&type=script&lang=js&"
export * from "./MessageOnboarding.vue?vue&type=script&lang=js&"
import style0 from "./MessageOnboarding.vue?vue&type=style&index=0&id=75b15453&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75b15453",
  null
  
)

export default component.exports