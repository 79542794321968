<template lang="html">
  <div v-if="message" class="col-12 current-message" :class="message.post_type">

    <div class="overflow-y-scroll">

      <p v-if="message.attachmentImageStoragePath && !imageUrl" class="text-center"><i class="far fa-fw fa-pulse fa-spinner"></i></p>

      <p v-if="catchUp && channelName" class="small channelName"><em>
        <span v-if="message.channel_type=='group'">Group </span>
        <span v-if="message.channel_type=='location'">Location </span>
        <span v-if="message.channel_type=='role'">Role </span>
        Channel: {{ channelName }}
      </em></p>

      <div v-if="(message.attachmentImageStoragePath && imageUrl && imageUrlCropped) || (message.tagged_user_id && taggedUser && taggedUser.profilePhotoStoragePath && taggedUserPhoto && taggedUserPhotoCropped)" class="attachment-container text-center">

        <div class="attachment-overlay">
          <div v-if="message.post_type == 'heads_up'" class="post-badge-round post-badge-heads-up">
            <img src="../assets/img/icon-exclamation-outline.svg" alt="!" width="10px" height="27px">
          </div>
          <div v-if="message.post_type == 'high_five'" class="post-badge-round post-badge-high-five">
            <img src="../assets/img/icon-high-five-outline.svg" alt="" width="29px" height="33px">
          </div>
        </div> <!-- /.attachment-overlay -->

        <div class="bump-overlay">
          <span v-for="i in bumpIncrement" v-if="bumpIncrement" class="bubble bubble-anim text-center"><img src="../assets/img/icon-clap-outline.svg" alt=""></span>
          <div class="bump d-flex align-items-center">
            <span v-if="bumpTimeout" class="bump-spinner"><i class="far fa-fw fa-pulse fa-spinner"></i></span>
            <span class="icon-plus-round d-flex align-items-center justify-content-center"><i class="far fa-plus"></i></span>
            <button class="btn-bump" @click="bumpMessage">
              <span class="post-badge-round post-badge-clap">
                <img src="../assets/img/icon-clap-outline.svg" alt="" width="32px" height="32px">
              </span>
            </button>
            <span v-if="message.bump_count" class="bump-count">{{ message.bump_count + bumpIncrement + savedBumps }}</span>
            <span v-if="!message.bump_count" class="bump-count">{{ bumpIncrement + savedBumps }}</span>
          </div>
        </div> <!-- /.bump-overlay -->

        <badge v-if="taggedUserBadge" :badge="taggedUserBadge" class="badge-overlay  badge-overlay-lg" :size="'lg'"></badge>

        <a v-if="imageUrl" :href="imageUrl" target="_blank" class="img-crop" :style="'background:url(' + imageUrlCropped + ');background-size:cover;'"></a>
        <a v-if="!imageUrl" :href="taggedUserPhoto" target="_blank" class="img-crop" :style="'background:url(' + taggedUserPhotoCropped + ');background-size:cover;'"></a>
      </div>

      <div v-if="!message.attachmentImageStoragePath && !taggedUserPhoto" class="icon-lg text-center">

        <div class="bump-overlay">
          <span v-for="i in bumpIncrement" v-if="bumpIncrement" class="bubble bubble-anim text-center"><img src="../assets/img/icon-clap-outline.svg" alt=""></span>
          <div class="bump d-flex align-items-center">
            <span v-if="bumpTimeout" class="bump-spinner"><i class="far fa-fw fa-pulse fa-spinner"></i></span>
            <span class="icon-plus-round d-flex align-items-center justify-content-center"><i class="far fa-plus"></i></span>
            <button class="btn-bump" @click="bumpMessage">
              <span class="post-badge-round post-badge-clap">
                <img src="../assets/img/icon-clap-outline.svg" alt="" width="32px" height="32px">
              </span>
            </button>
            <span v-if="message.bump_count" class="bump-count">{{ message.bump_count + bumpIncrement + savedBumps }}</span>
            <span v-if="!message.bump_count" class="bump-count">{{ bumpIncrement + savedBumps }}</span>
          </div>
        </div> <!-- /.bump-overlay -->
        
        <img v-if="message.post_type == 'heads_up'" src="../assets/img/icon-lg-heads-up@2x.png" alt="!" width="75px" height="200px">
        <img v-if="message.post_type == 'high_five'" src="../assets/img/icon-lg-high-five@2x.png" alt="!" width="180px" height="206px">
      </div>
      
      <h1 v-if="message.post_type == 'heads_up'">Head's Up!</h1>
      <h1 v-if="message.post_type == 'high_five'">High Five<span v-if="taggedUserName"> {{ taggedUserName }}</span>!</h1>
      <p class="lead">{{ message.messageText }}</p>

      <div v-if="citation" class="citation w-100 d-flex justify-content-center align-items-center">
        <p v-if="!citationPhoto && userPostedBy && userPostedBy.profilePhotoStoragePath" class="snug loader"><i class="far fa-fw fa-pulse fa-spinner"></i></p>
        <div v-if="citationPhoto" class="img-profile-container">
          <badge v-if="citationBadge" :badge="citationBadge" class="badge-overlay  badge-overlay-sm" :size="'sm'"></badge>
          <div
            class="img-profile"
            :class="{'img-badge': citationBadge}"
            :style="{'border-color': citationBadge ? citationBadge.badge_color : 'transparent'}"
          >
            <img :src="citationPhoto" class="img-fluid" alt="profile photo" />
          </div>
        </div>
        <p class="snug">
          <em class="sm light-weight">From:</em><br>
          {{ citation }}
        </p>
      </div>

      <p v-if="message.attachmentLink" class="attachment text-center">
        <a :href="$sanitizeUrl(message.attachmentLink)" target="_blank" class="btn btn-icon-left btn-light"><i class="far fa-link"></i> Open Attached Link</a>
      </p>


    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import Firebase from 'firebase/compat/app'
import store from '../store'
import {mapGetters} from 'vuex'
import Badge from '../components/Badge.vue'

export default {
  props: ['message', 'catchUp', 'badges'],
  components: {
    Badge
  },
  data () {
    return {
      userPostedBy: null,
      citation: null,
      citationPhoto: null,
      taggedUser: null,
      taggedUserName: null,
      taggedUserPhoto: null,
      taggedUserPhotoCropped: null,
      imageUrl: null,
      imageUrlCropped: null,
      bumpIncrement: 0,
      bumpTimeout: null,
      savedBumps: 0,
      channelName: null,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),
    citationBadge() {
      if(this.badges && this.badges.length && this.userPostedBy && this.userPostedBy.badge_level) {
        return this.badges.find(b => b.badge_level==this.userPostedBy.badge_level);
      }
      return null;
    },
    taggedUserBadge() {
      if(this.badges && this.badges.length && this.taggedUser && this.taggedUser.badge_level) {
        return this.badges.find(b => b.badge_level==this.taggedUser.badge_level);
      }
      return null;
    },
  },
  methods: {
    loadChannelName() {
      if(!this.catchUp || !this.message || !this.message.channel_type || !this.message.customerId) {
        return;
      }
      // decide name of channel
      if(this.message.channel_type == 'allLocations') {
        this.channelName = "All Locations";
        return;
      }
      if(this.message.channel_type == 'group' && this.message.groupId) {
        // load group by message.groupId
        Vue.firestore.doc(`customers/${this.message.customerId}/groups/${this.message.groupId}`)
          .get()
          .then(snap => {
            if(!snap.empty) {
              this.channelName = snap.data().groupName;
            }
          })
          .catch(err => console.log(err));
      }
      if(this.message.channel_type == 'location' && this.message.locationId) {
        // load group by message.locationId
        Vue.firestore.doc(`customers/${this.message.customerId}/groups/${this.message.groupId}/locations/${this.message.locationId}`)
          .get()
          .then(snap => {
            if(!snap.empty) {
              this.channelName = snap.data().locationName;
            }
          })
          .catch(err => console.log(err));
      }
      if(this.message.channel_type == 'role' && this.message.roleId) {
        // load group by message.roleId
        Vue.firestore.doc(`customers/${this.message.customerId}/roles/${this.message.roleId}`)
          .get()
          .then(snap => {
            if(!snap.empty) {
              this.channelName = snap.data().roleName;
            }
          })
          .catch(err => console.log(err));
      }
      
    },
    // used to set both citationPhoto & taggedUserPhoto
    loadPhoto (storagePath,varName) {
      if(!storagePath) {
        return;
      }
      // console.log(`loading photo ${varName} at ${storagePath}`);
      Vue.storage.ref().child(storagePath).getDownloadURL()
        .then((result) => {
          // only show if correct filetype
            // allow only jpg, png, gif, webp, avif, tiff
          if(result.includes('.jpg') || result.includes('.jpeg') || result.includes('.png') || result.includes('.gif') || result.includes('.webp') || result.includes('.avif') || result.includes('.tiff')) {
            this[varName] = result;
          }
        })
        .catch(e => console.log(e));
    },
    // duplicated in MessagePreview.vue
      // changes: always load author name
    getCitation() {
      if(!this.message || !this.custom_claims || !this.custom_claims.customerId) {
        return;
      }

      // load author name
      if(this.message.posted_by) {
        Vue.firestore.collection('customers')
          .doc(this.custom_claims.customerId)
          .collection('users')
          .doc(this.message.posted_by)
          .get()
          .then(userQuerySnap => {
            if(userQuerySnap.exists) {
              // console.log('found citation')
              this.userPostedBy = userQuerySnap.data();
              this.citation = userQuerySnap.data().name || userQuerySnap.data().email;
              if(userQuerySnap.data().profilePhotoStoragePath) {
                let storagePath = userQuerySnap.data().profilePhotoStoragePath;
                const thumbStoragePath = storagePath.substring(0, storagePath.lastIndexOf(".")) + "_500x500" + storagePath.substring(storagePath.lastIndexOf("."));
                return this.loadPhoto(thumbStoragePath,'citationPhoto')
              }
            }
          })
          .catch(err => console.log(err));
      } else {
        // cancel loading
        this.citation = '';
      }
      // load tagged user
      if(this.message.post_type == 'high_five') {
        // load tagged user name
        if(this.message.tagged_user_id) {
          Vue.firestore.collection('customers')
            .doc(this.custom_claims.customerId)
            .collection('users')
            .doc(this.message.tagged_user_id)
            .get()
            .then(userQuerySnap => {
              if(userQuerySnap.exists) {
                // console.log('found citation')
                this.taggedUser = userQuerySnap.data();
                this.taggedUserName = userQuerySnap.data().name || userQuerySnap.data().email;
                if(userQuerySnap.data().profilePhotoStoragePath) {
                  let storagePath = userQuerySnap.data().profilePhotoStoragePath;
                  const thumbStoragePath = storagePath.substring(0, storagePath.lastIndexOf(".")) + "_500x500" + storagePath.substring(storagePath.lastIndexOf("."));
                  this.loadPhoto(thumbStoragePath,'taggedUserPhotoCropped')
                  return this.loadPhoto(userQuerySnap.data().profilePhotoStoragePath,'taggedUserPhoto')
                }
              }
            })
            .catch(err => console.log(err));
        } else {
          // cancel loading
          this.citation = '';
        }
      }
      // TODO: load location name for google review post type
      // else if(this.message.post_type == 'review') {}

    },
    markRead() {
      if(!this.user || !this.user.uid || !this.custom_claims || !this.custom_claims.customerId || !this.message) {
        return;
      }
      // console.log('call markRead')
      let read_by_users = [];
      if(this.message.read_by_users && this.message.read_by_users.length) {
        // if the user already read it, exit
        if(this.message.read_by_users.includes(this.user.uid)) {
          // console.log('already read')
          return;
        }
        // if array exists, store it
        read_by_users = this.message.read_by_users;
      }
      // add this user
      read_by_users.push(this.user.uid)
      // add this user to message's read_by_users array
      Vue.firestore.doc(`customers/${this.custom_claims.customerId}/messages/${this.message.id}`)
        .update({
          read_by_users: read_by_users,
        })
        .then(() => {
          // console.log('message marked as read')
        })
        .catch(err => console.log(err));
    },
    getImageAttachment() {
      this.imageUrl = null; // reset
      if(!this.user || !this.user.uid || !this.custom_claims || !this.custom_claims.customerId || !this.message || !this.message.attachmentImageStoragePath) {
        return;
      }
      let storagePath = this.message.attachmentImageStoragePath;
      const thumbStoragePath = storagePath.substring(0, storagePath.lastIndexOf(".")) + "_500x500" + storagePath.substring(storagePath.lastIndexOf("."));
      this.loadPhoto(thumbStoragePath,'imageUrlCropped');
      this.loadPhoto(this.message.attachmentImageStoragePath,'imageUrl');
    },
    bumpMessage() {
      if(!this.user || !this.user.uid || !this.custom_claims || !this.custom_claims.customerId || !this.message) {
        return;
      }
      // console.log('call bumpMessage')
      this.bumpIncrement++;
      // run timeout on window so we can clear it in separate function
      this.bumpTimeout = window.setTimeout(() => this.saveBumps(),1000);

      // award points for bumping once per day
      const dateCode = new Date().toISOString().slice(0, 10).replaceAll('-', '');
      if(!this.user_props.points_bumped_as_of || this.user_props.points_bumped_as_of !== dateCode) {
        Vue.firestore.collection('users')
          .doc(this.user.uid)
          .update({
            // do not use FieldValue.increment, instead do math manually
            // points: Firebase.firestore.FieldValue.increment(10),
            points: this.user_props.points ? this.user_props.points+5 : 5,
            // save datecode to prevent awarding more than once a day
            points_bumped_as_of: dateCode,
          });
      }
    },
    saveBumps() {
      window.clearTimeout(this.bumpTimeout);
      // save a copy of current increment in case it changes during saving
      const bCount = this.bumpIncrement;
      // store before saving in case they click again
      this.savedBumps += bCount;
      this.bumpIncrement = 0;
      // save to db
      Vue.firestore.doc(`customers/${this.custom_claims.customerId}/messages/${this.message.id}`)
        .update({
          bump_count: Firebase.firestore.FieldValue.increment(bCount),
        })
        .then(() => {
          // console.log('message bumps saved')
          this.bumpTimeout = null; // hide loading indicator
        })
        .catch(err => {
          console.log(err);
          this.bumpTimeout = null;
          this.savedBumps -= bCount;
        });
    }
  },
  mounted() {
    this.getCitation();
    this.markRead();
    this.getImageAttachment();
    this.loadChannelName();
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.current-message {
  width: 100%;
  height: 100%;
}
.current-message.heads_up {
  background-color: @color-blue;
}
.current-message.high_five {
  background-color: @color-orange;
}
.icon-lg {
  padding: 30px 0;
  margin: 20px auto;
  position: relative;
  // add circle to match img attachments & anchor bump overlay
  width: 250px;
  height: 250px;
  border-radius: 250px;
  border: 1px solid rgba(255,255,255,0.5);
}
h1 {
  overflow-wrap: break-word;
  text-align: center;
  max-width: 80%;
  margin: 0 auto 40px;
}
// for channel name when catchUp==true
.channelName {
  text-align: center;
  margin: 20px 0;
}
p.lead {
  font-size: 22px;
  line-height: 28px;
  padding: 40px 0 30px 40px;
  position: relative;
  background: url('../assets/img/bg-blockquote@2x.png') 0 30px no-repeat;
  background-size: 29px 24px;
  border-top: 1px solid rgba(255,255,255,0.2);
  border-bottom: 1px solid rgba(255,255,255,0.2);
  max-width: 90%;
  margin: 0 auto 15px;
}
.overflow-y-scroll {
  height: 100%;
  overflow-y: auto;
}
// attachment header
.attachment-container {
  display: block;
  position: relative;
  margin: 30px auto;
  width: 250px;
  
  .img-crop {
    display: block;
    border: 2px solid @light-color;
    width: 250px;
    height: 250px;
    text-align: center;
    overflow: hidden;
    border-radius: 250px;
    background-repeat: no-repeat;
    background-position: center center;
    // background-size: cover; // redundant, must be set after applying background in style prop
  }
  
  .attachment-overlay {
    position: absolute;
    top: 15px;
    left: 15px;
  }
}
// post type badges
  // copied from .btn-round in ActivityView.vue
.post-badge-round {
  width: 44px; // add 4 for borders
  height: 44px;
  line-height: 40px;
  overflow: hidden;
  border-radius: 40px;
  margin-left: 10px;
  text-align: center;
  border: 2px solid @light-color;
  
  img {
    vertical-align: baseline; // browser default
    transition: all 250ms ease-in-out;
  }
}
.post-badge-heads-up {
  background-color: @color-blue;

  img {
    vertical-align: text-top; // slightly off v-center but prefer option to bottom
  }
}
.post-badge-high-five {
  background-color: @color-orange;

  img {
    vertical-align: bottom;
  }
}
.post-badge-clap {
  background-color: @color-green;

  img {
    vertical-align: middle; // slightly off v-center but prefer option to bottom
  }
}
// attachment btns
p.attachment {
  border-top: 1px solid rgba(255,255,255,0.2);
  padding-top: 20px;
}
p.attachment + p.attachment {
  border-top: none;
  padding-top: 0;
}
.btn.btn-icon-left:hover {
  // override colors
  background-color: @dark-color !important;
  color: @light-color !important;
}
// bump message
.bump-overlay {
  position: absolute;
  bottom: 5px;
  left: 175px;
}
.bump {
  position: relative;
  min-width: 65px;
  height: 50px;
}
.icon-plus-round {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: @light-color;
    color: @dark-color;
    font-size: 14px;
    line-height: 14px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
.btn-bump {
  // overrides
  border-radius: 0;
	font-size: 16px;
	line-height: 1em;
	padding: 0;
	text-decoration: none;
	box-shadow: none;
  border: none !important;
  outline: none;
  margin: 0;
  // custom
  background-color: transparent !important;
  text-align: left;
  display: block;
  position: absolute;
  bottom: 3px;
  left: 5px;
  z-index: 1;

  .post-badge-round {
    display: inline-block;
    margin: 0;
  }
}
.bump-count {
  display: block;
  width: 100%;
  text-align: right;
  padding: 1px 3px 3px 40px;
  margin-left: 10px;
  background-color: @light-color;
  font-size: 14px;
  line-height: 14px;
  color: @dark-color;
  border-radius: 3px;
}
.bump-spinner {
  position: absolute;
  left: -14px;
  top: 17px;
  color: @light-color;
  background-color: fadeout(@dark-color,80%);
  border-radius: 50px;
  font-size: 12px;
}
.bubble {
  display: block;
  width: 28px;
  height: 28px;
  border-radius: 50px;
  background-color: @light-color;
  position: absolute;
  top: 0;
  left: 12px;
  z-index: 100;
  opacity: 0;
}
.bubble-anim {
  animation: 500ms 1 growup ease-out;
}
@keyframes growup {
  from {
    display: block;
    opacity: 0;
    top: 0;
    scale: 10%;
  }
  75%{
    opacity: 1;
    top: -40px;
    scale: 100%;
  }
  to {
    opacity: 0;
    top: -45px;
    scale: 10%;
  }
}
// citation photo block
.citation {
  .loader + p,
  .img-profile-container + p {
    margin-left: 10px;
  }
  p {
    line-height: 16px;
  }
  .sm {
    line-height: 14px;
  }
}
</style>
