<template lang="html">
  <div v-show="channel" class="post">

    <div class="title-bar d-flex justify-content-center align-items-center">
      <i class="far fa-check"></i>
      <span class="badge-round badge-high-five"><img src="../assets/img/icon-high-five-outline.svg" alt="" width="29px" height="33px"></span>
      <h2>High Five!</h2>
    </div>

    <div class="col-instructions col text-center">
      <h4>Give a high five to the entire <strong>{{ channel.name }}</strong> channel, or choose a team member to receive the high five!</h4>
      <p v-if="loading" class="text-center">
        <i class="far fa-fw fa-pulse fa-spinner"></i><br />
        Please wait. saving &amp; uploading...
      </p>
    </div>

    <div class="col" v-if="!loading">
      <p v-if="loadingUsers" class="text-center"><i class="far fa-fw fa-pulse fa-spinner"></i></p>
      <p v-if="!loadingUsers && !Object.keys(users).length" class="text-center alert alert-custom-light color-error"><em>Oops, there's no one in this channel.</em></p>

      <input v-if="!loadingUsers && Object.keys(users).length" v-model="userSearchString" name="search" class="form-control input-user-search" placeholder="Search user name/email to tag" type="text"/>
      <div v-if="!loadingUsers && Object.keys(filteredUsers).length" class="user-list d-flex flex-column">
        <user-select-item
          v-for="(tagUser, uid) in filteredUsers"
          @click.native="taggedUserId = uid"
          :tagUser="tagUser"
          :key="uid"
          :badges="badges"
        ></user-select-item>
      </div>
      <p v-if="!loadingUsers && Object.keys(filteredUsers).length > 4" class="search-instruction small text-center"><em><i class="far fa-angle-down"></i> swipe to scroll <i class="far fa-angle-down"></i></em></p>
      <p v-if="!loadingUsers && !Object.keys(filteredUsers).length" class="search-instruction small"><em>no users found</em></p>

      <p v-if="taggedUserId">
        <strong>Tagged:</strong><br />
        {{ users[taggedUserId].name | limitStringLength(20)  }} ( {{ users[taggedUserId].email | limitStringLength(20) }} )
        <button class="btn btn-remove" @click="taggedUserId=null"><i class="far fa-times"></i></button>
      </p>


      <!-- <select v-if="!loadingUsers && Object.keys(users).length" v-model="taggedUserId" class="form-control">
        <option disabled :value="null">Select User</option>
        <option v-for="(user, uid) in users" :value="uid">{{ user.name || user.email }}</option>
      </select> -->

      <list-errors :errors="errors" class="text-center"></list-errors>
      <textarea v-model="messageText" class="form-control" placeholder="Enter message..." max-length="140" @keydown="limitTextAreaLength"></textarea>
      <p class="sm light-weight text-center"><em>140 character limit</em></p>

      <div v-if="includeImage" class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text"><i class="far fa-image"></i></div>
        </div>
        <input @change="previewFiles" class="form-control" placeholder="Select a file" type="file" accept="image/*" />
      </div>
      <div v-if="includeLink" class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text"><i class="far fa-link"></i></div>
        </div>
        <input v-model="linkUrl" class="form-control" placeholder="Enter a url" type="url" />
      </div>

      <p class="text-center">
        <button v-if="!includeImage" @click.prevent="includeImage = true" class="btn btn-icon-left btn-light"><i class="far fa-image"></i> Add Image</button>
        <button v-if="!includeLink" @click.prevent="includeLink = true" class="btn btn-icon-left btn-light"><i class="far fa-link"></i> Add Link</button>
      </p>

    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import Firebase from 'firebase/compat/app'
import {mapGetters} from 'vuex'
import ListErrors from '../components/ListErrors.vue'
import UserSelectItem from '../components/UserSelectItem.vue'

export default {
  props: ['channel', 'share', 'badges'],
  components: {
    ListErrors, UserSelectItem,
  },
  data () {
    return {
      loading: false,
      loadingUsers: false,
      messageText: null,
      errors: [],
      taggedUserId: null,
      users: {}, // objects of objects are only reactive if set with this.$set(this.users, id, value)
      includeImage: false,
      includeLink: false,
      linkUrl: null,
      imageFile: null,
      userSearchString: null,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),
    filteredUsers() {
      if(!this.userSearchString) {
        return this.users;
      }
      const entries = Object.entries(this.users);
      const filtered = entries.filter(([uid,u]) => {
        // console.log('uid',uid);
        // console.log('u',u);
        if((u.name && u.name.toLowerCase().includes(this.userSearchString.toLowerCase())) || (u.email && u.email.toLowerCase().includes(this.userSearchString.toLowerCase()))) {
          return [uid,u];
        }
      })
      return Object.fromEntries(filtered);
    },
  },
  methods: {
    previewFiles(event) {
      // console.log('event.target.files',event.target.files);
      this.imageFile = event.target.files[0];
    },
    limitTextAreaLength(e) {
      if(this.messageText && this.messageText.length >= 140) {
        // console.log('keydown',e)
        // isComposing and keyCode 229 are for accessibility
        if ( !e.isComposing && e.keyCode !== 229 && e.keyCode !== 46 && e.keyCode !== 8 && e.keyCode !== 13 ) {
          // console.log('message too long')
          e.preventDefault();
        }
      }
    },
    // load users for the active channel
    loadUsers() {
      // console.log('loadUsers')
      this.loadingUsers = true;
      this.users = {};

      if(!this.custom_claims || !this.custom_claims.customerId) {
        return;
      }
      let usersQueryRef;
      let adminsQueryRef;
      // if group channel
      if(this.channel.type == "group") {
        // prerequisite: must load all the group's locations to get their ids
        // get users who have a location in this group || this group's groupAdmins || superAdmin
        usersQueryRef = Vue.firestore.doc(`customers/${this.custom_claims.customerId}`).collection('users')
          .where('customerId', '==', this.custom_claims.customerId)
          .where('status', '==', 'accepted')
          .where('locations', 'array-contains-any', this.channel.locations);
        // add 2nd query for groupAdmins
        adminsQueryRef  = Vue.firestore.doc(`customers/${this.custom_claims.customerId}`).collection('users')
          .where('customerId', '==', this.custom_claims.customerId)
          .where('status', '==', 'accepted')
          .where('adminLevel', '==', `GroupAdmin_${this.channel.id}`);
      }
      // if allLocations
      if(this.channel.type == "allLocations") {
        // get all users with customerId
        usersQueryRef = Vue.firestore.doc(`customers/${this.custom_claims.customerId}`).collection('users')
          .where('customerId', '==', this.custom_claims.customerId)
          .where('status', '==', 'accepted');
      }
      // if location channel
      if(this.channel.type == "location") {
        // get all users who have this location || this location's groups' groupAdmin || superAdmin
        usersQueryRef = Vue.firestore.doc(`customers/${this.custom_claims.customerId}`).collection('users')
          .where('customerId', '==', this.custom_claims.customerId)
          .where('status', '==', 'accepted')
          .where('locations', 'array-contains', this.channel.id);
        // add 2nd query for admins
        adminsQueryRef  = Vue.firestore.doc(`customers/${this.custom_claims.customerId}`).collection('users')
          .where('customerId', '==', this.custom_claims.customerId)
          .where('status', '==', 'accepted')
          .where('adminLevel', '==', `GroupAdmin_${this.channel.groupId}`);
      }
      // if role channel
      if(this.channel.type == "role") {
        // get all users who have this role || superAdmin
        usersQueryRef = Vue.firestore.doc(`customers/${this.custom_claims.customerId}`).collection('users')
          .where('customerId', '==', this.custom_claims.customerId)
          .where('status', '==', 'accepted')
          .where('roles', 'array-contains', this.channel.id);
      }

      // track all promises to finish loading indicator
      let getUsersPromises = []

      // include superAdmins if not allLocations, since they are already included there
      if(this.channel.type !== "allLocations") {
        let superAdminsProm = Vue.firestore.doc(`customers/${this.custom_claims.customerId}`).collection('users')
          .where('customerId', '==', this.custom_claims.customerId)
          .where('status', '==', 'accepted')
          .where('adminLevel', '==', 'superAdmin')
          .get();
        getUsersPromises.push(superAdminsProm);
        superAdminsProm.then(superAminUserQuerySnap => {
          if(superAminUserQuerySnap.empty) {
            // console.log("couldn't find super admin users in your account.")
          }
          for(const [i,u] of superAminUserQuerySnap.docs.entries()) {
            // console.log(u.data(), u.id);
            // store users with id
            // use $set to make reactive in Vue
            this.$set(this.users, u.id, {
              ...u.data(),
            });
          }
        })
        .catch(err => console.log(err));
      }

      // run users query
      if(usersQueryRef) {
        let usersProm = usersQueryRef.get();
        getUsersPromises.push(usersProm);
        usersProm.then(userQuerySnap => {
            if(userQuerySnap.empty) {
              // console.log("couldn't find users in this channel.")
            }
            for(const [i,u] of userQuerySnap.docs.entries()) {
              // console.log(u.data(), u.id);
              // store users with id
              // use $set to make reactive in Vue
              this.$set(this.users, u.id, {
                ...u.data(),
              });
            }
          })
          .catch(err => console.log(err));
      }

      // run group admins query
      if(adminsQueryRef) {
        let adminsProm = adminsQueryRef.get();
        getUsersPromises.push(adminsProm);
        adminsProm.then(adminUserQuerySnap => {
          if(adminUserQuerySnap.empty) {
            // console.log("couldn't find admin users in your account.")
          }
          for(const [i,u] of adminUserQuerySnap.docs.entries()) {
            // console.log(u.data(), u.id);
            // store users with id
            // use $set to make reactive in Vue
            this.$set(this.users, u.id, {
              ...u.data(),
            });
          }
        })
      }

      // when all users loaded
      Promise.all(getUsersPromises).then(() => {
        // delete self from list if exists at end
        if(this.users[this.user.uid]) {
          // console.log('deleting self from tag list')
          this.$delete(this.users,this.user.uid);
        }
        this.loadingUsers = false;
      })
    },
  },
  watch: {
    share() {
      if(this.share) {
        // console.log("saving a head's up");
        this.loading = true;
        this.errors = [];
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        // validate post
        if(!this.custom_claims || !this.custom_claims.customerId || !this.custom_claims.adminLevel) {
          this.errors.push("Sorry, you don't have permission to post. Please contact a super admin.")
        }
        if(!this.messageText || !this.messageText.trim().length || this.messageText.trim().length <= 5) {
          // console.log(this.messageText.trim().length)
          this.errors.push('Please enter a message longer than 5 characters.')
        }
        // if a user is tagged, validate them from the users array
        if(this.taggedUserId && !this.users[this.taggedUserId]) {
          this.errors.push('Please choose a valid team member to receive your High Five!')
        }
        // validate image
        if(this.includeImage && !this.imageFile) {
          this.errors.push('Please choose an image to attach.')
        }
        // allow only jpg, png, gif, webp, avif, tiff
        if(this.imageFile && this.imageFile.name) {
          let filenameSC = this.imageFile.name.toLowerCase();
          if( this.includeImage && (!filenameSC.includes('.jpg') && !filenameSC.includes('.jpeg') && !filenameSC.includes('.png') && !filenameSC.includes('.gif') && !filenameSC.includes('.webp') && !filenameSC.includes('.avif') && !filenameSC.includes('.tiff')) ) {
            this.errors.push('This image is not a valid filetype. Only jpg, png, gif, webp, avif, and tiff are allowed');
          }
        }
        
        // validate link
        // https://stackoverflow.com/a/15734347/2913914
        if( this.includeLink && ( !this.linkUrl || !this.linkUrl.trim().length || this.linkUrl.trim().length <= 5 || this.linkUrl.includes(' ') || !urlRegex.test(this.linkUrl) ) ) {
          this.errors.push('Sorry, the link URL is not valid.')
        }
        if(this.errors.length) {
          this.loading = false;
          this.$emit('clear-share');
          return;
        }


        let message = {
          customerId: this.channel.customerId,
          timestamp: Math.floor(Date.now() / 1000),
          date: new Date().toISOString().slice(0, 10).replaceAll('-',''), // like 20220914, for easy filtering
          posted_by: this.user.uid,
          channel_type: this.channel.type,
          post_type: 'high_five',
          messageText: this.messageText,
          tagged_user_id: this.taggedUserId,
        };

        // if group
        if(this.channel.type == "group") {
          message.groupId = this.channel.id;
        }
        // if all locations
        if(this.channel.type == "allLocations") {
          // do nothing?
        }
        // if location
        if(this.channel.type == "location") {
          message.groupId = this.channel.groupId;
          message.locationId = this.channel.id;
        }
        // if role
        if(this.channel.type == "role") {
          message.roleId = this.channel.id;
        }

        // attach link
        if(this.includeLink) {
          message.attachmentLink = this.$sanitizeUrl(this.linkUrl);
        }

        // upload image
        let storagePath;
        let uploadProm = Promise.resolve(true);
        if(this.includeImage && this.imageFile) {
          // storage path structure: customers/customer_id/images/uid.png
          // generate unique filename
          let fileName = self.crypto.randomUUID();
          console.log(`fileName`,fileName);
          let regex = /(?:\.([^.]+))?$/;
          // index 1 will return extension with no dot
          let fileExtension = regex.exec(this.imageFile.name)[1];
          console.log(`fileExtension`,fileExtension);
          let newFileName = `${fileName}.${fileExtension}`;
          console.log(`newFileName`,newFileName);
          storagePath = `customers/${this.custom_claims.customerId}/images/${newFileName}`;
          // save storage path on message
          message.attachmentImageStoragePath = storagePath;
          // set promise for upload
          let ref = Vue.storage.ref().child(storagePath);
          uploadProm = ref.put(this.imageFile);
        }
        // open batch
        var batch = Vue.firestore.batch();

        // batch message save
        const messageRef = Vue.firestore.collection('customers')
          .doc(this.custom_claims.customerId)
          .collection('messages')
          .doc();
        batch.set(messageRef, message);

        // batch rewards points
        const highFivePointsValue = 5;
        // posted by user
        const postedByUserRef = Vue.firestore.collection('users')
          .doc(this.user.uid);
        batch.update(postedByUserRef, {
          // do not use FieldValue.increment, instead to math manually to avoid duplicate awards
          // points: Firebase.firestore.FieldValue.increment(highFivePointsValue),
          points: this.user_props.points ? this.user_props.points+highFivePointsValue : highFivePointsValue,
        });
        // tagged user
        if(this.taggedUserId) {
          const taggedUserRef = Vue.firestore.collection('users')
            .doc(this.taggedUserId);
          batch.update(taggedUserRef, {
            points: Firebase.firestore.FieldValue.increment(highFivePointsValue),
          });
        }

        // save post
        // console.log({message});
        uploadProm
          .catch(err => {
            this.errors.push(err.message);
            this.loading = false;
            this.$emit('clear-share');
            return false;
          })
          .then((success) => {
            if(success) {
              return batch.commit();
            } else {
              return false;
            }
          })
          .then(() => {
            console.log('message saved successfully');
            this.loading = false;
            this.$emit('clear-share');
            this.$emit('end-share');
          })
          .catch(err => console.log(err));
      }
    },
  },
  mounted() {
    // console.log('mounted', this.channel)
    if(this.channel) {
      // console.log('mounted w/ channel', this.channel)
      // load prerequisite data
      this.loadUsers();
    }
  }
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.post {
  height: 100%;
  background-color: @color-orange;
  border-top: 1px solid @dark-color;
  overflow-y: auto;
}
.title-bar {
  padding: 20px 0;
  background-color: @light-color;

  h3 {
    margin: 0;
  }
}
.col-instructions {
  padding-top: 20px;
}
textarea.form-control {
  height: 150px;
  margin-bottom: 10px;
}
// attachments
.btn.btn-icon-left:hover {
  // override colors
  background-color: @dark-color !important;
  color: @light-color !important;
}
.btn-icon-left + .btn-icon-left {
  margin-left: 20px;
}

// user search
.input-user-search {
  margin-bottom: 5px;
}
.user-list {
  max-height: 200px;
  overflow-y: scroll;
  background-color: @light-color;
  border: 1px solid @dark-color;
  margin-bottom: 20px;
}
</style>
