<template lang="html">
  <div class="stat-block">
    <div>
      <p v-if="moRangeTitle" class="snug">
        <strong>Total Practice Health</strong><br>
        <span>{{ moRangeTitle }}</span>
      </p>
    </div>

    <div class="chart chart-bars chart-bar-stacked">
      <div class="bars-container d-flex flex-row">
        <div
          class="bar-container d-flex flex-row"
          :class="barColorClass"
        >
          <div class="bar bar-dark" :style="{width: revBarWidth + '%'}"></div>
          <div class="bar bar-light" :style="{width: schedBarWidth + '%'}"></div>
          <div v-if="remainderBarWidth > 0" class="bar bar-remainder" :style="{width: remainderBarWidth + '%'}"></div>
        </div>
      </div> <!-- /.bars-container -->
      <div class="labels-x d-flex flex-row justify-content-between">
        <p class="sm">0</p>
        <p class="sm">${{ Number(activeGoal).toLocaleString() }}</p>
      </div> <!-- /.labels-x -->
    </div> <!-- /.chart -->



    <!-- calculated ranges -->
    <!-- *10 before rounding then /10 after to keep 1 decimal place -->
    <div class="range-display">
      <p>
        <span class="color-swatch color-swatch-dark"></span>
        Completed Mo to Date Net Prod: ${{ (Math.round(totalRevenue/1000*10)/10).toLocaleString() }}k
      </p>
      <p>
        <span class="color-swatch color-swatch-light"></span>
        Sched. Production This Mo: ${{ (Math.round(scheduledRevenue/1000*10)/10).toLocaleString() }}k
      </p>
      <p>
        <span
          class="color-swatch color-swatch-orange"
          :class="{'swatch-active': activeGoal == moDeliveringMin}"
        >
          <i v-if="combinedRevenue >= moDeliveringMin" class="far fa-check-circle"></i>
        </span>
        <span v-if="combinedRevenue < moDeliveringMin">Gap to Delivering The Way: ${{ (Math.round((moDeliveringMin-combinedRevenue)/1000*10)/10).toLocaleString() }}k</span>
        <span v-if="combinedRevenue >= moDeliveringMin">Delivering The Way: <em>goal met!</em></span>
      </p>
      <p>
        <span
          class="color-swatch color-swatch-blue"
          :class="{'swatch-active': activeGoal == moGrowingMin}"
        >
          <i v-if="combinedRevenue >= moGrowingMin" class="far fa-check-circle"></i>
        </span>
        <span v-if="combinedRevenue < moGrowingMin">Gap to Growing The Way: ${{ (Math.round((moGrowingMin-combinedRevenue)/1000*10)/10).toLocaleString() }}k</span>
        <span v-if="combinedRevenue >= moGrowingMin">Growing The Way: <em>goal met!</em></span>
      </p>
      <p>
        <span
          class="color-swatch color-swatch-green"
          :class="{'swatch-active': activeGoal == moLeadingMin}"
        >
          <i v-if="combinedRevenue >= moLeadingMin" class="far fa-check-circle"></i>
        </span>
        <span v-if="combinedRevenue < moLeadingMin">Gap to Leading The Way: ${{ (Math.round((moLeadingMin-combinedRevenue)/1000*10)/10).toLocaleString() }}k</span>
        <span v-if="combinedRevenue >= moLeadingMin">Leading The Way: <em>goal met!</em></span>
      </p>
      <p>
        <span
          class="color-swatch color-swatch-purple"
          :class="{'swatch-active': activeGoal == moScalingMin}"
        >
          <i v-if="combinedRevenue >= moScalingMin" class="far fa-check-circle"></i>
        </span>
        <span v-if="combinedRevenue < moScalingMin">Gap to Scaling The Way: ${{ (Math.round((moScalingMin-combinedRevenue)/1000*10)/10).toLocaleString() }}k</span>
        <span v-if="combinedRevenue >= moScalingMin">Scaling The Way: <em>goal met!</em></span>
      </p>
      
      
      
    </div>
  </div> <!-- /.col -->
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import ListErrors from '../components/ListErrors.vue'
import moment from 'moment'

export default {
  props: ['totalRevenue','activeMonthlyRevenueGoal','scheduledRevenue','activeLocationId','activeLocation'],
  computed: {
    monthAsNumber() {
      // month is zero-indexed from 0-11, so add 1
      return moment().month() + 1;
    },
    dayOfMonth() {
      // return 14; // testing
      return moment().subtract(1, 'days').date();
    },
    daysInMonth() {
      return moment().daysInMonth();
    },
    activeGoal() {
      if(!this.combinedRevenue && this.combinedRevenue!==0) {
        return null;
      }
      // return next higher dollar amount after each goal is met
      // if rev+sched > 110%, return 120%
      if(this.combinedRevenue >= this.moLeadingMin) {
        return this.moScalingMin;
      }
      // if rev+sched > 100%, return 110%
      if(this.combinedRevenue >= this.moGrowingMin) {
        return this.moLeadingMin;
      }
      // if rev+sched > 90%, return 100%
      if(this.combinedRevenue >= this.moDeliveringMin) {
        return this.moGrowingMin;
      }
      // else return 90%
      return this.moDeliveringMin;
    },
    revBarWidth() {
      if((this.totalRevenue || this.totalRevenue==0) && (this.scheduledRevenue || this.scheduledRevenue==0) && this.activeGoal) {
        if((this.totalRevenue + this.scheduledRevenue) < this.activeGoal) {
          return this.totalRevenue / this.activeGoal * 100;
        } else {
          return this.totalRevenue/(this.totalRevenue+this.scheduledRevenue)*100;
        }
      }
    },
    schedBarWidth() {
      if((this.totalRevenue || this.totalRevenue==0) && (this.scheduledRevenue || this.scheduledRevenue==0) && this.activeGoal) {
        if((this.totalRevenue + this.scheduledRevenue) < this.activeGoal) {
          return this.scheduledRevenue / this.activeGoal * 100;
        } else {
          return this.totalRevenue/(this.totalRevenue+this.scheduledRevenue)*100;
        }
      }
    },
    remainderBarWidth() {
      if((this.totalRevenue || this.totalRevenue==0) && (this.scheduledRevenue || this.scheduledRevenue==0) && this.activeGoal) {
        if((this.totalRevenue + this.scheduledRevenue) < this.activeGoal) {
          return 100 - ((this.totalRevenue + this.scheduledRevenue) / this.activeGoal * 100);
        } else {
          return 0;
        }
      }
    },
    // monthly goal calculations
    combinedRevenue() {
      if((this.scheduledRevenue || this.scheduledRevenue==0) && (this.totalRevenue || this.totalRevenue==0)) {
        return Math.round(this.scheduledRevenue + this.totalRevenue);
      }
      return null;
    },
    moDeliveringMin() {
      if(this.activeMonthlyRevenueGoal) {
        return Math.round(this.activeMonthlyRevenueGoal*.9);
      }
      return null;
    },
    moDeliveringMax() {
      if(this.activeMonthlyRevenueGoal) {
        return Math.round(this.activeMonthlyRevenueGoal*.9999);
      }
      return null;
    },
    moGrowingMin() {
      if(this.activeMonthlyRevenueGoal) {
        return Math.round(this.activeMonthlyRevenueGoal);
      }
      return null;
    },
    moGrowingMax() {
      if(this.activeMonthlyRevenueGoal) {
        return Math.round(this.activeMonthlyRevenueGoal*1.0999);
      }
      return null;
    },
    moLeadingMin() {
      if(this.activeMonthlyRevenueGoal) {
        return Math.round(this.activeMonthlyRevenueGoal*1.1);
      }
      return null;
    },
    moLeadingMax() {
      if(this.activeMonthlyRevenueGoal) {
        return Math.round(this.activeMonthlyRevenueGoal*1.1999);
      }
      return null;
    },
    moScalingMin() {
      if(this.activeMonthlyRevenueGoal) {
        return Math.round(this.activeMonthlyRevenueGoal*1.2);
      }
      return null;
    },
    // classes & titles
    moRangeTitle() {
      if(!this.combinedRevenue && this.combinedRevenue !== 0) {
        return null;
      }
      if(this.combinedRevenue < this.moDeliveringMin) {
        return 'Time to Catch Up!';
      }
      if(this.combinedRevenue <= this.moDeliveringMax) {
        return 'Delivering The Way';
      }
      if(this.combinedRevenue <= this.moGrowingMax) {
        return 'Growing The Way';
      }
      if(this.combinedRevenue <= this.moLeadingMax) {
        return 'Leading The Way';
      }
      if(this.combinedRevenue >= this.moLeadingMax) {
        return 'Scaling The Way';
      }
    },
    barColorClass() {
      if(!this.combinedRevenue && this.combinedRevenue !== 0) {
        return null;
      }
      // if(this.combinedRevenue <= this.moDeliveringMin) {
      //   return 'red';
      // }
      if(this.combinedRevenue < this.moDeliveringMin) {
        return 'orange';
      }
      if(this.combinedRevenue < this.moGrowingMin) {
        return 'blue';
      }
      if(this.combinedRevenue < this.moLeadingMin) {
        return 'green';
      }
      if(this.combinedRevenue >= this.moLeadingMin) {
        return 'purple';
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.chart {
  margin-top: 10px;
}
.bar-container {
  .bar-dark {
    background-color: @dark-color;
  }
  .bar-light {
    border-left: none;
    background-color: @light-color;
  }
}
.swatch-active {
  border: 1px solid @dark-color;
}
// override remainder bar styles to make solid
.purple .bar-remainder  {
  background-color: @color-magenta;
  border-color: @dark-color;
}
.green .bar-remainder  {
  background-color: @color-green;
  border-color: @dark-color;
}
.blue .bar-remainder  {
  background-color: @color-blue;
  border-color: @dark-color;
}
.orange .bar-remainder  {
  background-color: @color-orange;
  border-color: @dark-color;
}
.red .bar-remainder  {
  background-color: @color-salmon;
  border-color: @dark-color;
}
</style>
