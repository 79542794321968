<template>
  <layout-private
    :pageTitle="'Rewards'"
    :showBackBtn="$route.name=='RewardsList'"
    v-on:back="handleBack"
  >
    <div class="page">

      <transition name="slide-on-left">
        <router-view />
      </transition>

    </div>
  </layout-private>
</template>

<script>
import Vue from 'vue'
import LayoutPrivate from '../components/LayoutPrivate.vue'
import {mapGetters} from 'vuex'
import ListErrors from '../components/ListErrors.vue'

export default {
  props: ['customer'],
  components: {
    LayoutPrivate, ListErrors
  },
  data () {
    return {
      errors: [],
      loading: false,
      userList: null,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims', 'custom_claims']),
    
  },
  watch: {
    
  },
  methods: {
    handleBack() {
      if(this.$route.name=='RewardsList') {
        this.$router.push('/rewards');
      }
    },
  },
  mounted () {
    
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";
.page {
  padding: 30px 15px 0;
  position: relative;
  margin: 0 -15px;
  overflow-x: hidden;
  min-height: 100%;
}
// overrides
// slide off to the left
.slide-off-left-enter-active, .slide-off-left-leave-active {
  top: 30px;
}

// slide on from the right
.slide-on-left-enter-active, .slide-on-left-leave-active {
  top: 30px;
}
</style>
