import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

import AccountView from './views/AccountView.vue'
import LogInView from './views/LogInView.vue'
import ResetPasswordView from './views/ResetPasswordView.vue'
import ActivityView from './views/ActivityView.vue'
import AdminView from './views/AdminView.vue'
import StatsView from './views/StatsView.vue'
import HowToJoinView from './views/HowToJoinView.vue'
import RewardsView from './views/RewardsView.vue'
import RewardsLeaderboardView from './views/RewardsLeaderboardView.vue'
import RewardsListView from './views/RewardsListView.vue'

Vue.use(Router)

const routes = [
  // Public
  // ----------
  {
    path: '/log-in',
    name: 'logIn',
    component: LogInView,
    meta: {
      pageTitle: 'Log In',
    }
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: ResetPasswordView,
    meta: {
      pageTitle: 'Reset Password',
    }
  },
  {
    path: '/how-to-join',
    name: 'howToJoin',
    component: HowToJoinView,
    meta: {
      pageTitle: 'How To Join Your Team',
    }
  },
  // --------------------
  // Private
  // --------------------
  {
    path: '/account',
    name: 'account',
    component: AccountView,
    meta: {
      pageTitle: 'Account',
      authRequired: true,
    }
  },
  {
    path: '/',
    name: 'activity',
    component: ActivityView,
    meta: {
      pageTitle: 'Activity',
      authRequired: true,
    }
  },
  {
    path: '/stats',
    name: 'stats',
    component: StatsView,
    meta: {
      pageTitle: 'Stats',
      authRequired: true,
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    meta: {
      pageTitle: 'Admin',
      authRequired: true,
    }
  },
  {
    path: '/rewards',
    name: 'rewards',
    component: RewardsView,
    meta: {
      pageTitle: 'Rewards',
      authRequired: true,
    },
    children: [
      {
        path: '/rewards',
        name: 'RewardsLeaderboard',
        component: RewardsLeaderboardView,
      },
      {
        path: '/rewards-list',
        name: 'RewardsList',
        component: RewardsListView,
      },
    ]
  },

  // Lazy loaded views
  // ----------
    // route level code-splitting
    // this generates a separate chunk ([name].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },

  // ----------
  // 404
  // explicit path used for redirects
  // {
  //   path: "/page-not-found",
  //   name: '404',
  //   component: PageNotFound,
  //   meta: {
  //     pageTitle: 'Page Not Found',
  //   }
  // },
  // // catchall path
  // {
  //   path: "*",
  //   component: PageNotFound,
  //   meta: {
  //     pageTitle: 'Page Not Found',
  //   }
  // }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  // always scroll to top of new page
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  // if page requires authentication
  if ( to.matched.some(record => record.meta.authRequired) ) {
    // if no user, force login
    if (!store.state.user) {
      next({
        path: '/log-in',
        query: { redirect: to.fullPath }
      })
    }
    else {
      // console.log('passed route reqs')
      next()
    }
  }
  else {
    // console.log('no route reqs')
    next()
  }
})

// update page title
const DEFAULT_TITLE = 'Smiley Mobile';
router.afterEach((to, from) => {
  if(to.meta.pageTitle) {
    document.title = `${to.meta.pageTitle} | ${DEFAULT_TITLE}`;
  } else {
    document.title = DEFAULT_TITLE;
  }
  // track in GA
  Vue.analytics.logEvent('page_view');
});

export default router
