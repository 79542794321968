<template lang="html">
  <div v-if="message" class="col-12 current-message" :class="message.post_type">

    <div class="overflow-y-scroll">

      <p class="icon-lg text-center" v-if="message.post_type !== 'google_review'">
        <img v-if="message.post_type == 'heads_up'" src="../assets/img/icon-lg-heads-up@2x.png" alt="!" width="75px" height="200px">
        <img v-if="message.post_type == 'high_five'" src="../assets/img/icon-lg-high-five@2x.png" alt="!" width="180px" height="206px">

        <img v-if="message.post_type == 'smiley'" src="../assets/img/icon-smiley-lg@2x.png" alt="" width="175px" height="175px">
        <img v-if="message.post_type == 'custom_image' && message.custom_image" :src="require('../assets/img/' + message.custom_image)" alt="" width="360px">
      </p>

      <div v-if="message.post_type == 'google_review'" class="google-review-title d-flex flex-column justify-content-center text-center">
        <p class="snug"><i class="fas fa-star color-yellow"></i><i class="fas fa-star color-yellow"></i><i class="fas fa-star color-yellow"></i><i class="fas fa-star color-yellow"></i><i class="fas fa-star color-yellow"></i></p>
        <h1 v-if="message.custom_title">{{ message.custom_title }}</h1>
      </div>

      <h1 v-if="message.post_type == 'heads_up' && !message.custom_title">Head's Up!</h1>
      <h1 v-if="message.post_type == 'high_five' && !message.custom_title">High Five<span v-if="taggedUserName"> {{ taggedUserName }}</span>!</h1>
      <h1 v-if="message.custom_title && message.post_type !== 'google_review'">{{ message.custom_title }}</h1>
      <p class="lead" v-html="message.messageText"></p>
      <p v-if="message.attachmentLink" class="attachment text-center">
        <a :href="$sanitizeUrl(message.attachmentLink)" target="_blank" class="btn btn-icon-left btn-light"><i class="far fa-link"></i> Open Attached Link</a>
      </p>
      <p v-if="message.attachmentImageStoragePath && imageUrl" class="attachment text-center">
        <a :href="imageUrl" target="_blank" class="btn btn-icon-left btn-light"><i class="far fa-image"></i> Open Attached Image</a>
      </p>

    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import store from '../store'
import {mapGetters} from 'vuex'

export default {
  props: ['message'],
  data () {
    return {
      citation: null,
      taggedUserName: null,
      imageUrl: null,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),
  },
  methods: {

  },
  mounted() {

  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.current-message {
  width: 100%;
  height: 100%;
}
.current-message.heads_up {
  background-color: @color-blue;
}
.current-message.high_five {
  background-color: @color-orange;
}
.current-message.smiley {
  background-color: @color-yellow;
}
.current-message.custom_image {
  background-color: @color-blue;
}
.current-message.google_review {
  background-color: @color-green;
}
.google-review-title {
  background: url('../assets/img/icon-lg-star.svg') center center no-repeat;
  background-size: 250px 237px;
  min-height: 237px;
  margin: 30px 0;
  padding-top: 20px;

  p, h1 {
    margin: 0;
    max-width: 100%;
  }
}
.icon-lg {
  padding: 30px 0;
  margin: 0;
}
h1 {
  overflow-wrap: break-word;
  text-align: center;
  max-width: 80%;
  margin: 0 auto 40px;
}
p.lead {
  font-size: 22px;
  line-height: 28px;
  padding: 0 40px 30px;
}
.overflow-y-scroll {
  height: 100%;
  overflow-y: auto;
}
// attachments
p.attachment {
  border-top: 1px solid rgba(255,255,255,0.2);
  padding-top: 20px;
}
p.attachment + p.attachment {
  border-top: none;
  padding-top: 0;
}
.btn.btn-icon-left:hover {
  // override colors
  background-color: @dark-color !important;
  color: @light-color !important;
}
</style>
