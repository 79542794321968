<template lang="html">
  <div v-show="messages" class="catch-up">
    <div @click="close" class="catch-up-container">

      <transition-group :name="swipingLeft ? 'swipeleft' : 'swiperight'" tag="div">
        <message
          v-for="(message,index) in messages"
          v-if="index == currentCatchupMessageIndex && message.post_type !== 'award'"
          :message="message"
          :catchUp="true"
          :key="message.id"
          :badges="badges"
          class="message"
        ></message>
        <message-award
          v-for="(message,index) in messages"
          v-if="index == currentCatchupMessageIndex && message.post_type == 'award'"
          :message="message"
          :catchUp="true"
          :key="message.id"
          class="message"
        ></message-award>
      </transition-group>

      <div v-if="messages && messages.length" class="controls">
        <div class="controls-mid">
          <span v-if="currentCatchupMessageIndex > 0" class="arr arr-left"></span>
          <span v-if="currentCatchupMessageIndex < messages.length-1" class="arr arr-right"></span>
        </div>
        <div class="controls-footer">
          <p v-if="removedMessages.length < messages.length" class="light-weight sm"><em>{{ messages.length - removedMessages.length }} to catch up!</em></p>
          <p v-if="removedMessages.length == messages.length" class="light-weight sm"><em>all caught up! tap to close.</em></p>
          <p v-if="messages && messages.length && messages.length > 11">...</p>
          <p v-if="messages && messages.length && messages.length <= 11" class="progress-container">
            <span
              v-for="(message, index) in messages"
              :key="index"
              class="progress-circle"
              :class="[currentCatchupMessageIndex == index ? 'progress-circle-filled' : '']"
            >
              <i v-if="removedMessages.some(id => id == message.id)" class="far fa-check"></i>
            </span>
          </p>
        </div>
      </div> <!-- /.controls -->

    </div> <!-- /.catch-up-container -->
  </div> <!-- /.catch-up -->
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import ListErrors from '../components/ListErrors.vue'
import Message from '../components/Message.vue'
import MessageAward from '../components/MessageAward.vue'
import Firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

export default {
  props: ['messages','badges'],
  components: {
    ListErrors, Message, MessageAward
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims', 'current_message']),
  },
  data () {
    return {
      currentCatchupMessageIndex: null,
      swipeXStart: null,
      swipingLeft: null, // used to switch between 2 css transitions for transition-group
      removedMessages: [],
    }
  },
  watch: {
    messages(newMessages, oldMessages) {
      // on router navigation
      if(newMessages) {
        this.currentCatchupMessageIndex = 0;
      }
    },
    currentCatchupMessageIndex(newIndex,oldIndex) {
      // console.log('newIndex',newIndex)
      // console.log('this.messages.length',this.messages.length)
      // remove from unread_messages when viewed
        // add ==0 because 0 value throws false negative
      if(newIndex || newIndex == 0) {
        // if not already deleted
        if(!this.removedMessages.some(id => id == this.messages[newIndex].id)) {
          // // ----- testing only:
          // console.log(`message index ${newIndex} id ${this.messages[newIndex].id} removed from unread_messages`)
          // this.removedMessages.push(this.messages[newIndex].id);

          // ----- live:
          const dateCode = new Date().toISOString().slice(0, 10).replaceAll('-', '');
          // open batch
          var batch = Vue.firestore.batch();
          const userPropsRef = Vue.firestore.doc(`users/${this.user.uid}`)
          batch.update(userPropsRef, {
            unread_messages: Firebase.firestore.FieldValue.arrayRemove(this.messages[newIndex].id),
            // if the last message to remove, also update caught up date
            caught_up_as_of: newIndex == this.messages.length-1 ? dateCode : (this.user_props.caught_up_as_of || null),
          });

          // if last message, also save caught up date on customer/users
          if(newIndex == this.messages.length-1 && this.custom_claims && this.custom_claims.customerId) {
            // console.log('updating on customer/users')
            const userCustRef = Vue.firestore.doc(`customers/${this.custom_claims.customerId}/users/${this.user.uid}`);
            batch.update(userCustRef, {
              caught_up_as_of: dateCode,
            });
          }
          
          batch.commit()
            .then(() => {
              // console.log(`message index ${newIndex} id ${this.messages[newIndex].id} removed from unread_messages`)
              this.removedMessages.push(this.messages[newIndex].id);
            })
            .catch(err => console.log(err));
        }
      }
    },
  },
  methods: {
    close() {
      if(this.removedMessages.length == this.messages.length) {
        this.$emit('close-catch-up');
      }
    },
    handleTouchStart(event) {
      // console.log('handleTouchStart',event);
      this.swipeXStart = event.touches[0].clientX;
    },
    handleTouchMove(event) {
      if(!this.swipeXStart) {
        return;
      }
      // console.log('handleTouchMove',event);
      const swipeXEnd = event.touches[0].clientX;
      const diff = this.swipeXStart - swipeXEnd;
      // if user swiped more than 50px
      if(Math.abs(diff) > 50) {
        if(swipeXEnd < this.swipeXStart) {
          // console.log('swipe left')
          // don't swipe left at end of array
          if(this.currentCatchupMessageIndex < this.messages.length-1) {
            this.swipingLeft = true;
            this.currentCatchupMessageIndex++;
          }
        } else {
          // console.log('swipe right')
          // don't swipe right at beginning of array
          if(this.currentCatchupMessageIndex >= 1) {
            this.swipingLeft = false;
            this.currentCatchupMessageIndex--;
          }
        }
        this.swipeXStart = null;
      }

    },
  },
  mounted() {
    document.addEventListener('touchstart', this.handleTouchStart, false);
    // listener for touchend doesn't work, so use touchmove instead
    document.addEventListener('touchmove', this.handleTouchMove, false);

    // on page refresh
    if(this.messages) {
      this.currentCatchupMessageIndex = 0;
    }
  },
  // prevent duplicate listeners when navigating away and then back
  beforeDestroy() {
    document.removeEventListener('touchstart', this.handleTouchStart, false);
    document.removeEventListener('touchmove', this.handleTouchMove, false);
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.catch-up {
  background-color: @light-color;
  height: 100%;
  width: 100%;
}
.catch-up-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  // select div created by transition-group and add padding
  div:first-child {

    height: 100%;
    // overflow-y: scroll;
  }
}
.message {
  width: 100%;
  min-width: 100%;
  height: 100%;
  // height: 75%;
  padding-bottom: 100px; // always keep space for controls-footer
}
.controls-footer {
  position: absolute;
  left: 0;
  bottom: 30px;
  width: 100%;
  z-index: 4100;
  text-align: center;

  p {
    margin: 0 0 10px;
  }
}
.controls-mid {
  .arr {
    display: block;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    margin-top: -15px;
    z-index: 4100;
  }
  .arr.arr-left {
    left: 30px;
    border-left: 2px solid @light-color;
    border-bottom: 2px solid @light-color;
  }
  .arr.arr-right {
    right: 30px;
    border-top: 2px solid @light-color;
    border-right: 2px solid @light-color;
  }
}
.progress-container {
  margin: 0;

  .progress-circle {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    border: 2px solid @dark-color;
    background-color: transparent;
    margin-right: 15px;
    position: relative;
  }
  .progress-circle-filled {
    background-color: @dark-color;
  }
  .progress-circle:last-child {
    margin: 0;
  }
  .progress-circle .fa-check {
    position: absolute;
    left: 0;
    top: -4px;
  }
  .progress-circle-filled .fa-check {
    color: @light-color;
  }
}
</style>
