<template>
  <layout-private
    :customer="customer"
    :pageTitle="pageTitle"
    :showBackBtn="activeChannel !== null && !showPostHeadsUp && !showPostHighFive && !expandPostOverlay"
    :showShareBtn="!share && (showPostHeadsUp || showPostHighFive)"
    :showCancelBtn="showPostHeadsUp || showPostHighFive"
    v-on:back="handleBack"
    v-on:cancel="showPostHeadsUp = false; showPostHighFive = false"
    v-on:share="share = true"
  >

    <transition name="fade">
      <post-heads-up
        v-if="showPostHeadsUp"
        :share="share"
        :channel="activeChannel"
        v-on:clear-share="share = false"
        v-on:end-share="showPostHeadsUp = false"
        class="post-modal"
      ></post-heads-up>
    </transition>

    <transition name="fade">
      <post-high-five
        v-if="showPostHighFive"
        :share="share"
        :channel="activeChannel"
        :badges="badges"
        v-on:clear-share="share = false"
        v-on:end-share="showPostHighFive = false"
        class="post-modal"
      ></post-high-five>
    </transition>

    <transition name="slide-on-left">
      <activity-channel
        v-if="activeChannel"
        v-show="!current_message"
        :channel="activeChannel"
        class="activeChannel"
      ></activity-channel>
    </transition>

    <transition name="slide-on-left">
      <message
        v-if="current_message && current_message.post_type !== 'award'"
        :message="current_message"
        :badges="badges"
        class="currentMessage"
      ></message>
    </transition>

    <transition name="slide-on-left">
      <message-award
        v-if="current_message && current_message.post_type == 'award'"
        :message="current_message"
        class="currentMessage"
      ></message-award>
    </transition>

    <transition name="fade">
      <catch-up
        v-if="unreadMessages && unreadMessages.length && !activeChannel && !showPostHeadsUp && !showPostHighFive && !current_message && hideOnboarding"
        :messages="unreadMessages"
        :badges="badges"
        class="catch-up-modal"
        v-on:close-catch-up="unreadMessages = null"
      ></catch-up>
    </transition>

    <transition name="fade">
      <onboarding
        v-if="!hideOnboarding"
        class="onboarding-modal"
        v-on:close-onboarding="hideOnboarding = true"
      ></onboarding>
    </transition>

    <transition name="fade">
      <div v-if="activeChannel && !showPostHeadsUp && !showPostHighFive && !current_message && custom_claims && custom_claims.adminLevel" class="post-buttons text-right">
        <transition name="fade">
          <div v-if="expandPostOverlay">
            <p class="small"><em class="light-weight">Post to:</em> <strong>{{ activeChannel.name }}</strong></p>
            <div v-if="custom_claims.adminLevel == 'superAdmin' || custom_claims.adminLevel.includes('GroupAdmin_')" class="row justify-content-end align-items-center">
              <p>Share a Head's Up</p>
              <button @click="showPostHeadsUp = true; showPostHighFive = false; expandPostOverlay = false" class="btn-round btn-heads-up"><img src="../assets/img/icon-exclamation-outline.svg" alt="!" width="10px" height="27px"></button>
            </div>
            <div class="row justify-content-end align-items-center">
              <p>Share a High Five</p>
              <button @click="showPostHeadsUp = false; showPostHighFive = true; expandPostOverlay = false" class="btn-round btn-high-five"><img src="../assets/img/icon-high-five-outline.svg" alt="" width="29px" height="33px"></button>
            </div>
          </div>
        </transition>
        <div class="row justify-content-end align-items-center">
          <transition name="fade">
            <p v-if="expandPostOverlay">cancel</p>
          </transition>
          <button @click="expandPostOverlay=!expandPostOverlay" :class="[expandPostOverlay ? 'btn-rotate-icon' : '']" class="btn-round btn-plus"><img src="../assets/img/icon-plus.svg" alt="+" width="15px" height="15px"></button>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="expandPostOverlay"  @click="expandPostOverlay=!expandPostOverlay" class="blur-overlay"></div>
    </transition>

    <div class="swipe-container">

      <transition name="slide-off-left">
        <div class="table-container" v-if="!activeChannel">
          <table class="table table-custom table-custom-borders">
            <tbody>
              <tr>
                <th v-if="custom_claims && custom_claims.adminLevel && custom_claims.adminLevel == 'superAdmin'"><button class="button-channel-selector" @click="activeChannel=allLocationsChannel">
                  <strong>All Locations</strong><i class="far fa-angle-right"></i>
                </button></th>
                <th v-if="!custom_claims || !custom_claims.adminLevel || custom_claims.adminLevel !== 'superAdmin'" class="pad">All Locations</th>
              </tr>
              <tr v-for="channel in locationChannels" v-if="Object.keys(locationChannels).length && !channel.hidden">
                <td><button class="button-channel-selector" @click="activeChannel=channel" :class="[channel.type == 'location' ? 'light-weight' : '']">
                    <span>{{ channel.name | limitStringLength(45) }}</span><i class="far fa-angle-right"></i>
                </button></td>
              </tr>
              <tr>
                <th class="pad">By Role</th>
              </tr>
              <tr v-for="channel in roleChannels" v-if="Object.keys(roleChannels).length && !channel.hidden">
                <td><button class="button-channel-selector light-weight" @click="activeChannel=channel">
                  <span>{{ channel.name | limitStringLength(45) }}</span><i class="far fa-angle-right"></i>
                </button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </transition>

    </div> <!-- /.swipe-container -->

  </layout-private>
</template>

<script>
import Vue from 'vue'
import LayoutPrivate from '../components/LayoutPrivate.vue'
import {mapGetters} from 'vuex'
import ListErrors from '../components/ListErrors.vue'
import ActivityChannel from '../components/ActivityChannel.vue'
import PostHeadsUp from '../components/PostHeadsUp.vue'
import PostHighFive from '../components/PostHighFive.vue'
import Message from '../components/Message.vue'
import MessageAward from '../components/MessageAward.vue'
import CatchUp from '../components/CatchUp.vue'
import Onboarding from '../components/Onboarding.vue'
import store from '../store'

export default {
  props: ['customer'],
  components: {
    LayoutPrivate, ListErrors, ActivityChannel, PostHeadsUp, PostHighFive, Message, CatchUp, Onboarding, MessageAward
  },
  data () {
    return {
      errors: [],
      loading: true,
      roleChannels: {},
      locationChannels: {},
      activeChannel: null,
      showPostHeadsUp: false,
      showPostHighFive: false,
      share: false,
      allLocationsChannel: {
        type: 'allLocations',
        name: 'All Locations',
        customerId: null,
        groups: [],
      },
      expandPostOverlay: false,
      unreadMessages: [],
      hideOnboarding: true,
      lastRefresh: null,
      badges: null,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims', 'current_message']),
    pageTitle() {
      if(this.user_props && !this.user_props.onboarding_complete) {
        return 'Get to Know Smiley';
      }
      if(this.unreadMessages && Object.keys(this.unreadMessages).length) {
        return 'Catch Up';
      }
      if(this.activeChannel) {
        return this.activeChannel.name;
      }
      return 'Channels';
    },
  },
  watch: {
    custom_claims (newClaims, oldClaims) {
      // console.log('custom claims updated, new:', newClaims);
      // console.log('old:', oldClaims);
      // only reset if channels changed
      if (
        // always allow first load
        !oldClaims ||
        // new locations
        (newClaims.locations && newClaims.locations.length !== Object.keys(this.locationChannels).length) || 
        // deleted all locations
        ((!newClaims.locations || !newClaims.locations.length) && Object.keys(this.locationChannels).length) || 
        // new roles
        (newClaims.roles && newClaims.roles.length !== Object.keys(this.roleChannels).length) || 
        // deleted all roles
        ((!newClaims.roles || !newClaims.roles.length) && Object.keys(this.roleChannels).length) || 
        // changed admin level
        (oldClaims && newClaims.adminLevel !== oldClaims.adminLevel)
      ) {
        // reset if reloading custom claims
        if(this.custom_claims && (Object.keys(this.roleChannels).length || Object.keys(this.locationChannels).length)) {
          this.locationChannels = {};
          this.roleChannels = {};
          this.activeChannel = null;
        }
        this.getChannels();
        if(this.custom_claims && this.custom_claims.customerId) {
          this.allLocationsChannel.customerId = this.custom_claims.customerId;
        }
      }
      // load badges list setting
      if(this.custom_claims && this.custom_claims.customerId && !this.badges) {
        this.loadBadges();
      }
    },
    user_props (newProps, oldProps) {
      if(newProps) {
        this.getUnreadMessages();
        // show onboarding if no flag found
        if(this.hideOnboarding && !newProps.onboarding_complete) {
          this.hideOnboarding = false;
        }
      }
    }
  },
  methods: {
    loadBadges() {
      // console.log('loadBadges')
      // load badges from firestore, save to this.badges, overwrite default badge
      if(this.custom_claims && this.custom_claims.customerId) {
        Vue.firestore.doc(`customers/${this.custom_claims.customerId}/settings/badges`)
        .get()
        .then(badgesSnap => {
          // console.log(badgesSnap.data());
          if(badgesSnap.exists && badgesSnap.data().badges_list && badgesSnap.data().badges_list.length) {
            this.badges = badgesSnap.data().badges_list;
          }
        })
      }
    },
    handleBack() {
      if(this.current_message) {
        store.dispatch('clearCurrentMessage');
      } else if(this.activeChannel) {
        this.activeChannel = null;
      } else {
        this.expandPostOverlay = false;
      }
    },
    // get all channels if has custom_claims
    async getChannels() {
      // console.log('getChannels');
      // exit if no sufficient claims
      if(!this.custom_claims || !this.custom_claims.adminLevel || !this.custom_claims.customerId) {
        return;
      }
      const adminLevel = this.custom_claims.adminLevel;
      const customerId = this.custom_claims.customerId;
      const locations = this.custom_claims.locations;
      const roles = this.custom_claims.roles;
      // get roles
      // ----------
      if(adminLevel == "superAdmin" || adminLevel.includes("GroupAdmin_")) {
        // get all roles
        // console.log('get all roles',customerId)
        let allRoles = Vue.firestore.collection('customers')
          .doc(customerId)
          .collection('roles')
          .get()
          .then(rolesQuerySnap => {
            if(rolesQuerySnap.empty) {
              console.log("couldn't find roles in your account.")
            }
            for(const [i,role] of rolesQuerySnap.docs.entries()) {
              // console.log(role.data(), role.id);
              this.$set(this.roleChannels, role.id, {
                customerId: customerId,
                type: 'role',
                name: role.data().roleName,
                id: role.id,
                hidden: role.data().hidden || null,
              });
            }
          })
          .catch(err => console.log(err));
      }
      else if(adminLevel == "mobileOnly" && roles && roles.length) {
        // get only specified roles
        let userRoles = this.custom_claims.roles;
        userRoles.forEach(roleId => {
          Vue.firestore.collection('customers')
            .doc(customerId)
            .collection('roles')
            .doc(roleId)
            .get()
            .then(rolesSnap => {
              this.$set(this.roleChannels, roleId, {
                customerId: customerId,
                type: 'role',
                name: rolesSnap.data().roleName,
                id: roleId,
                hidden: rolesSnap.data().hidden || null,
              });
            })
            .catch(err => console.log(err));
        })
      }

      // get locations & groups
      // ----------
      if(adminLevel == "superAdmin") {
        // get all groups
        let getSuperAdminGroups = await Vue.firestore.collection('customers')
          .doc(customerId)
          .collection('groups')
          .get()
          .then(groupsQuerySnap => {
            groupsQuerySnap.docs.forEach((grp,i) => {
              // console.log(`group ${i}`,grp.data());
              this.$set(this.locationChannels, grp.id, {
                customerId: customerId,
                type: 'group',
                name: grp.data().groupName,
                id: grp.id,
                locations: [],
                hidden: grp.data().hidden || null,
              });
              // all group to array in allLocations channel for its query
              this.$set(this.allLocationsChannel.groups, i, grp.id);
            });
          })
          .catch(err => console.log(err));
        // get all locations
        Vue.firestore.collectionGroup('locations')
          .where('customerId', '==', customerId)
          .get()
          .then(locsQuerySnap => {
            locsQuerySnap.docs.forEach((loc,i) => {
              // console.log(`loc ${i}`,loc.data());
              this.$set(this.locationChannels, loc.id, {
                customerId: customerId,
                type: 'location',
                name: loc.data().locationName,
                id: loc.id,
                groupId: loc.data().groupId,
                hidden: loc.data().hidden || null,
              });
              // add the location to the group channel's list of locations
                // instead of using i as index in $set, use length of array
                // for example a 2nd group would have its zero index skipped
                // resulting in an undefined location id in its locations array
              this.$set(this.locationChannels[loc.data().groupId].locations, this.locationChannels[loc.data().groupId].locations.length, loc.id);
            })
          })
          .catch(err => console.log(err));
      }
      else if(adminLevel.includes("GroupAdmin_")) {
        let adminGroupId = this.custom_claims.adminLevel.split("_")[1];
        // console.log({adminGroupId});
        // get group
        let getGroupAdminGroups = await Vue.firestore.collection('customers')
          .doc(customerId)
          .collection('groups')
          .doc(adminGroupId)
          .get()
          .then(groupSnap => {
            this.$set(this.locationChannels, adminGroupId, {
              customerId: customerId,
              type: 'group',
              name: groupSnap.data().groupName,
              id: adminGroupId,
              locations: [],
              hidden: groupSnap.data().hidden || null,
            });
          })
          .catch(err => console.log(err));
        // get all locations for that group
        Vue.firestore.collectionGroup('locations')
          .where('customerId', '==', customerId)
          .where('groupId', '==', adminGroupId)
          .get()
          .then(locsQuerySnap => {
            locsQuerySnap.docs.forEach((loc,i) => {
              // skip if already fetched this location for the groupAdmin section
                // these calls are synchronous, so check here too
              if(!this.locationChannels[loc.id]) {
                this.$set(this.locationChannels, loc.id, {
                  customerId: customerId,
                  type: 'location',
                  name: loc.data().locationName,
                  id: loc.id,
                  groupId: adminGroupId,
                  hidden: loc.data().hidden || null,
                });
                // add the location to the group channel's list of locations
                this.$set(this.locationChannels[adminGroupId].locations, i, loc.id);
              } else {
                // console.log(`skip group's duplicate location ${loc.id}`)
              }
            })
          })
          .catch(err => console.log(err));
      }
      // get specified locations, including for groupAdmins
        // in case they have locations outside their group
      if((adminLevel == "mobileOnly" || adminLevel.includes("GroupAdmin_")) && locations && locations.length) {
        // get specified locations
        Vue.firestore.collectionGroup('locations')
          .where('customerId', '==', customerId)
          .where('id', 'in', locations)
          .get()
          .then(locQuerySnap => {
            if(!locQuerySnap.empty) {
              locQuerySnap.docs.forEach((locSnap,i) => {
                let grpId = locSnap.ref.parent.parent.id;
                // skip if already fetched this location for the groupAdmin section
                if(!this.locationChannels[locSnap.id]) {
                  this.$set(this.locationChannels, locSnap.id, {
                    customerId: customerId,
                    type: 'location',
                    name: locSnap.data().locationName,
                    id: locSnap.id,
                    groupId: grpId,
                    hidden: locSnap.data().hidden || null,
                  });
                  // if the group channel exists
                  if(this.locationChannels[grpId]) {
                    this.$set(this.locationChannels[grpId].locations, i, loc.id);
                  }

                } else {
                  // console.log(`skip duplicate specified location ${locSnap.id}`)
                }
              })
            }
          })
          .catch(err => console.log(err));
      }
    },
    getUnreadMessages() {
      // console.log('getUnreadMessages');
      // only load unread messages once, not every time user_props updated
        // so we can remove messages from the user_props.unread_messages array
        // as they are read
      if((this.unreadMessages && this.unreadMessages.length) || !this.user_props || !this.user_props.unread_messages || !this.user_props.unread_messages.length || !this.custom_claims || !this.custom_claims.customerId) {
        return;
      }
      // avoid refreshing multiple times at once via window.addEventListener('focus')
      let now = Math.floor(Date.now());
      // console.log('now', now);
      // console.log('lastRefresh', this.lastRefresh);
      if(this.lastRefresh && (now - this.lastRefresh) < 1000) {
        // console.log("exit, has been < 1s since refresh")
        return;
      }
      this.lastRefresh = now;
      // continue loading
      this.unreadMessages = [];
      let newUnreadMessages = [];
      let unreadProms = [];
      // loop through unread messages ids
      this.user_props.unread_messages.forEach((messageId, index) => {
        let prom = Vue.firestore
          .doc(`customers/${this.custom_claims.customerId}/messages/${messageId}`)
          .get();
        unreadProms.push(prom);
        prom.then(docSnap => {
            if(docSnap.exists) {
              // console.log(docSnap.data())
              // store them in this.unreadMessages
              // do not store reactively as loaded, need to wait til end so we can track caught up date in CatchUp.vue line 91. if we don't wait, the # messages will change as we scroll through caught up modal, resulting in recording completion too early.
              // this.$set(this.unreadMessages, index, {
              //   ...docSnap.data(),
              //   id: messageId,
              // });
              newUnreadMessages.push({
                ...docSnap.data(),
                id: messageId,
              });
            }
          })
          .catch(err => console.log(err));
      });
      Promise.all(unreadProms).then(() => {
        this.unreadMessages = newUnreadMessages;
      })
      .catch(err => console.log(err));
    },
  },
  mounted () {
    // console.log('mounted');
    this.getChannels();
    if(this.custom_claims && this.custom_claims.customerId) {
      this.allLocationsChannel.customerId = this.custom_claims.customerId;
    }
    this.getUnreadMessages();
    // clear current message when navigating back to ActivityView from another view
    if(this.current_message) {
      store.dispatch('clearCurrentMessage');
    }
    // reload unread messages when app reopened
    window.addEventListener('focus', this.getUnreadMessages);
    // load badges list setting
    if(this.custom_claims && this.custom_claims.customerId && !this.badges) {
      this.loadBadges();
    }
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.swipe-container {
  position: relative;
  overflow-x: hidden;
  height: 100%;
  margin: 0 -15px;
}
.table-container,
.activeChannel,
.currentMessage {
  width: 100%;
  position: absolute;
}
.activeChannel,
.currentMessage {
  margin: 0 -15px;
}
.activeChannel {
  z-index: 100;
}
.currentMessage {
  z-index: 200; // overlap since .activeChannel won't swipe off using transitions
}
.channel-container {
  padding: 0;
}
.table {
  margin-top: 30px;
  height: auto;
}
.table.table-custom {
  td, th {
    padding: 0;
  }
  // for "by role" th w/o a button
  th.pad {
    padding: 25px 15px 15px;
  }
}
.button-channel-selector {
  // overrides
  border-radius: 0;
	font-size: 16px;
	line-height: 1em;
	padding: 25px 15px 15px;
	text-decoration: none;
	box-shadow: none;
  border: none !important;
  outline: none;
  // custom
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: transparent;
  color: @dark-color;

  &:hover {
    background-color: @color-blue;
  }
}
// button overlays
.post-buttons {
  position: fixed;
  bottom: 98px;
  right: 15px;
  z-index: 1001;

  .row {
    margin: 15px 0 0; // override
  }
  p {
    margin: 0;
  }

  .btn-round {
    // overrides
    text-decoration: none;
  	box-shadow: none;
    border: none !important;
    outline: none;
    padding: 0;
    // custom
    width: 40px;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    border-radius: 40px;
    margin-left: 10px;
    text-align: center;
    transition: all 250ms ease-in-out;

    img {
      vertical-align: baseline; // browser default
      transition: all 250ms ease-in-out;
    }
  }

  .btn-plus {
    background-color: @dark-color;
  }
  .btn-rotate-icon img {
    transform: rotate(45deg);
  }
  .btn-heads-up {
    background-color: @color-blue;

    img {
      vertical-align: text-top; // slightly off v-center but prefer option to bottom
    }
  }
  .btn-high-five {
    background-color: @color-orange;

    img {
      vertical-align: bottom;
    }
  }
}
.blur-overlay {
  backdrop-filter: blur(22px);
  // width: 100%;
  // height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
// post modals
.post-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3000;
}
// catch up modal
.onboarding-modal,
.catch-up-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4000;
}
.onboarding-modal {
  z-index: 5000;
}
</style>
