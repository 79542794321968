<template lang="html">
  <div v-show="channel" class="post">

    <div class="title-bar d-flex justify-content-center align-items-center">
      <i class="far fa-check"></i>
      <span class="badge-round badge-heads-up"><img src="../assets/img/icon-exclamation-outline.svg" alt="!" width="10px" height="27px"></span>
      <h2>Head's Up!</h2>
    </div>

    <div class="col-instructions col text-center">
      <h4>All team members in the <strong>{{ channel.name }}</strong> channel will get your head's up</h4>
      <p v-if="loading" class="text-center">
        <i class="far fa-fw fa-pulse fa-spinner"></i><br />
        Please wait. saving &amp; uploading...
      </p>
    </div>

    <div class="col" v-if="!loading">
      <!-- TODO: add google profile photo -->

      <list-errors :errors="errors" class="text-center"></list-errors>

      <textarea v-model="messageText" class="form-control" placeholder="Enter message..." max-length="140" @keydown="limitTextAreaLength"></textarea>
      <p class="sm light-weight text-center"><em>140 character limit</em></p>

      <div v-if="includeImage" class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text"><i class="far fa-image"></i></div>
        </div>
        <input @change="previewFiles" class="form-control" placeholder="Select a file" type="file" accept="image/*" />
      </div>
      <div v-if="includeLink" class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text"><i class="far fa-link"></i></div>
        </div>
        <input v-model="linkUrl" class="form-control" placeholder="Enter a url" type="url" />
      </div>

      <p class="text-center">
        <button v-if="!includeImage" @click.prevent="includeImage = true" class="btn btn-icon-left btn-light"><i class="far fa-image"></i> Add Image</button>
        <button v-if="!includeLink" @click.prevent="includeLink = true" class="btn btn-icon-left btn-light"><i class="far fa-link"></i> Add Link</button>
      </p>

    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import ListErrors from '../components/ListErrors.vue'

export default {
  props: ['channel', 'share'],
  components: {
    ListErrors
  },
  data () {
    return {
      loading: false,
      messageText: null,
      errors: [],
      includeImage: false,
      includeLink: false,
      linkUrl: null,
      imageFile: null,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),
  },
  methods: {
    limitTextAreaLength(e) {
      if(this.messageText && this.messageText.length >= 140) {
        // console.log('keydown',e)
        // isComposing and keyCode 229 are for accessibility
        if ( !e.isComposing && e.keyCode !== 229 && e.keyCode !== 46 && e.keyCode !== 8 && e.keyCode !== 13 ) {
          // console.log('message too long')
          e.preventDefault();
        }
      }
    },
    previewFiles(event) {
      // console.log('event.target.files',event.target.files[0]);
      this.imageFile = event.target.files[0];
    },
  },
  watch: {
    channel() {
      if(this.channel) {
        // load prerequisite data
      }
    },
    share() {
      if(this.share) {
        // console.log("saving a head's up");
        this.loading = true;
        this.errors = [];
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        // validate post
        if(!this.custom_claims || !this.custom_claims.customerId || (this.custom_claims.adminLevel != 'superAdmin' && !this.custom_claims.adminLevel.includes('GroupAdmin_'))) {
          this.errors.push("Sorry, you don't have permission to post. Please contact a super admin.")
        }
        if(!this.messageText || !this.messageText.trim().length || this.messageText.trim().length <= 5) {
          // console.log(this.messageText.trim().length)
          this.errors.push('Please enter a message longer than 5 characters.')
        }
        // validate image
        if(this.includeImage && !this.imageFile) {
          this.errors.push('Please choose an image to attach.')
        }
        // allow only jpg, png, gif, webp, avif, tiff
        if(this.imageFile && this.imageFile.name) {
          let filenameSC = this.imageFile.name.toLowerCase();
          if( this.includeImage && (!filenameSC.includes('.jpg') && !filenameSC.includes('.jpeg') && !filenameSC.includes('.png') && !filenameSC.includes('.gif') && !filenameSC.includes('.webp') && !filenameSC.includes('.avif') && !filenameSC.includes('.tiff')) ) {
            this.errors.push('This image is not a valid filetype. Only jpg, png, gif, webp, avif, and tiff are allowed');
          }
        }
        // validate link
        // https://stackoverflow.com/a/15734347/2913914
        if( this.includeLink && ( !this.linkUrl || !this.linkUrl.trim().length || this.linkUrl.trim().length <= 5 || this.linkUrl.includes(' ') || !urlRegex.test(this.linkUrl) ) ) {
          this.errors.push('Sorry, the link URL is not valid.')
        }
        if(this.errors.length) {
          this.loading = false;
          this.$emit('clear-share');
          return;
        }

        let message = {
          customerId: this.channel.customerId,
          timestamp: Math.floor(Date.now() / 1000),
          date: new Date().toISOString().slice(0, 10).replaceAll('-',''), // like 20220914, for easy filtering
          posted_by: this.user.uid,
          channel_type: this.channel.type,
          post_type: 'heads_up',
          messageText: this.messageText,
        };

        // if group
        if(this.channel.type == "group") {
          message.groupId = this.channel.id;
        }
        // if all locations
        if(this.channel.type == "allLocations") {
          // do nothing?
        }
        // if location
        if(this.channel.type == "location") {
          message.groupId = this.channel.groupId;
          message.locationId = this.channel.id;
        }
        // if role
        if(this.channel.type == "role") {
          message.roleId = this.channel.id;
        }

        // attach link
        if(this.includeLink) {
          message.attachmentLink = this.$sanitizeUrl(this.linkUrl);
        }

        // upload image
        let storagePath;
        let uploadProm = Promise.resolve(true);
        if(this.includeImage && this.imageFile) {
          // // storage path structure: practice_account_id/signatures/uid.png
          // generate unique filename
          let fileName = self.crypto.randomUUID();
          console.log(`fileName`,fileName);
          let regex = /(?:\.([^.]+))?$/;
          // index 1 will return extension with no dot
          let fileExtension = regex.exec(this.imageFile.name)[1];
          console.log(`fileExtension`,fileExtension);
          let newFileName = `${fileName}.${fileExtension}`;
          console.log(`newFileName`,newFileName);
          storagePath = `customers/${this.custom_claims.customerId}/images/${newFileName}`;
          // save storage path on message
          message.attachmentImageStoragePath = storagePath;
          // set promise for upload
          let ref = Vue.storage.ref().child(storagePath);
          uploadProm = ref.put(this.imageFile);
        }
        // save post
        // console.log({message});
        uploadProm
          .catch(err => {
            this.errors.push(err.message);
            this.loading = false;
            this.$emit('clear-share');
            return false;
          })
          .then((success) => {
            if(success) {
              return Vue.firestore.collection('customers')
                .doc(this.custom_claims.customerId)
                .collection('messages')
                .doc()
                .set(message);
            } else {
              return false;
            }
          })
          .then(() => {
            console.log('message saved successfully');
            this.loading = false;
            this.$emit('clear-share');
            this.$emit('end-share');
          })
          .catch(err => console.log(err));
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.post {
  height: 100%;
  background-color: @color-blue;
  border-top: 1px solid @dark-color;
  overflow-y: auto;
}
.title-bar {
  padding: 20px 0;
  background-color: @light-color;

  h3 {
    margin: 0;
  }
}
.col-instructions {
  padding-top: 20px;
}
textarea.form-control {
  height: 150px;
  margin-bottom: 10px;
}
// attachments
.btn.btn-icon-left:hover {
  // override colors
  background-color: @dark-color !important;
  color: @light-color !important;
}
.btn-icon-left + .btn-icon-left {
  margin-left: 20px;
}
</style>
