<template lang="html">
  <button class="btn btn-user-list-option d-flex align-items-center">
    <p v-if="!userPhoto && tagUser.profilePhotoStoragePath" class="snug loader"><i class="far fa-fw fa-pulse fa-spinner"></i></p>
    <div v-if="userPhoto" class="select-user-photo img-profile-container">
      <badge v-if="activeBadge" :badge="activeBadge" class="badge-overlay  badge-overlay-sm" :size="'sm'" :disableTooltip="true"></badge>
      <div
        class="img-profile"
        :class="{'img-badge': activeBadge}"
        :style="{'border-color': activeBadge ? activeBadge.badge_color : 'transparent'}"
      >
        <img :src="userPhoto" class="img-fluid" alt="profile photo" />
      </div>
    </div>
    <span class="select-user-name">
      {{ tagUser.name | limitStringLength(15)  }} ( {{ tagUser.email | limitStringLength(15) }} )
    </span>
  </button>
</template>

<script>
import Vue from 'vue'
import Firebase from 'firebase/compat/app'
import store from '../store'
import {mapGetters} from 'vuex'
import Badge from '../components/Badge.vue'

export default {
  props: ['tagUser','badges'],
  components: {
    Badge
  },
  data () {
    return {
      userPhoto: false,
    }
  },
  computed: {
    activeBadge() {
      if(this.badges && this.badges.length && this.tagUser.badge_level) {
        return this.badges.find(b => b.badge_level==this.tagUser.badge_level);
      }
      return null;
    },
  },
  watch: {
    tagUser() {
      if(this.tagUser && this.tagUser.profilePhotoStoragePath) {
        this.loadProfilePhoto();
      }
    },
  },
  methods: {
    loadProfilePhoto() {
      if(!this.tagUser || !this.tagUser.profilePhotoStoragePath) {
        console.log('not photo to load');
        return;
      }
      let storagePath = this.tagUser.profilePhotoStoragePath;
      const thumbStoragePath = storagePath.substring(0, storagePath.lastIndexOf(".")) + "_500x500" + storagePath.substring(storagePath.lastIndexOf("."));
      Vue.storage.ref().child(thumbStoragePath).getDownloadURL()
        .then((result) => {
          console.log(result)
          // only show if correct filetype
            // allow only jpg, png, gif, webp, avif, tiff
          if(result.includes('.jpg') || result.includes('.jpeg') || result.includes('.png') || result.includes('.gif') || result.includes('.webp') || result.includes('.avif') || result.includes('.tiff')) {
            this.userPhoto = result;
          }
        })
        .catch(e => console.log(e));
    },
  },
  mounted() {
    if(this.tagUser && this.tagUser.profilePhotoStoragePath && !this.userPhoto) {
      this.loadProfilePhoto();
    }
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.btn.btn-user-list-option {
  border-bottom: 1px solid #eee !important;
  text-align: left;

  &:hover,
  &:focus,
  &:active {
		color: @light-color;
    background-color: @dark-color;
    border-bottom-color: @dark-color;
    box-shadow: none;
    outline: none;
	}
  .loader + .select-user-name,
  .select-user-photo + .select-user-name {
    margin-left: 10px;
  }
}
.user-list + p.search-instruction {
  margin-top: -20px;
  margin-bottom: 30px;
}
</style>
