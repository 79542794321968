<template lang="html">
  <div class="col-12">
    <hr class="hr-chart" />

    <p class="subtitle"><strong>Practice Health</strong></p>
    <h3>Practice Health Goal</h3>
    <p>Are we on track for our production goals?</p>

    <hr v-if="activeLocation" class="text-divider" />

    <p v-if="!activeLocationId || !activeLocation" class="light-weight"><em>Please select a location above.</em></p>
    <div v-if="activeLocation && (!activeLocation.openDentalStatus || activeLocation.openDentalStatus !== 'active')">
      <p class="small"><em>No Open Dental connection found for this location.</em></p>
    </div>
    <p v-if="activeLocation && activeLocation.openDentalStatus && (!totalRevenue && totalRevenue !==0)"><i class="far fa-fw fa-pulse fa-spinner"></i></p>
    <!-- display revenue goal-->
    <p v-if="activeMonthlyRevenueGoal" class="goal-display">
      <em>Monthly Net Production Goal: ${{ Number(activeMonthlyRevenueGoal).toLocaleString() }}</em>
    </p>
    <p v-if="!activeMonthlyRevenueGoal && activeLocation" class="goal-display">
      <em>No Monthly Net Production Goal has been set for this location. Please notify an admin.</em>
    </p>
    <!-- display monthly days -->
    <p v-if="activeMonthlyDays" class="goal-display">
      <em>Average days open per month: {{ Number(activeMonthlyDays) }}</em>
    </p>
    <p v-if="!activeMonthlyDays" class="goal-display">
      <em>No average days open per month has been set for this location. Please notify an admin.</em>
    </p>

    <hr v-if="dayRevGoalLeading" class="text-divider" />

    <p v-if="dayRevGoalLeading" class="light-weight"><em>
      Daily production goal for Leading the Way: <br>
      ${{ dayRevGoalLeading.toLocaleString() }}
    </em></p>

    <stats-prod-prev-day
    v-if="activeLocation && activeLocation.openDentalStatus && activeLocation.openDentalStatus == 'active' && dayRevGoal"
    :dayRevGoal="dayRevGoal"
    :dayRevGoalLeading="dayRevGoalLeading"
    :activeLocationId="activeLocationId"
    :activeLocation="activeLocation"
    :activeMonthlyRevenueGoal="activeMonthlyRevenueGoal"
  ></stats-prod-prev-day>

  <stats-prod-today
    v-if="activeLocation && activeLocation.openDentalStatus && activeLocation.openDentalStatus == 'active' && dayRevGoal"
    :dayRevGoal="dayRevGoal"
    :dayRevGoalLeading="dayRevGoalLeading"
    :todaySchedRevenue="todaySchedRevenue"
    :activeLocationId="activeLocationId"
    :activeLocation="activeLocation"
    :activeMonthlyRevenueGoal="activeMonthlyRevenueGoal"
  ></stats-prod-today>

  <stats-prod-tomorrow
    v-if="activeLocation && activeLocation.openDentalStatus && activeLocation.openDentalStatus == 'active' && dayRevGoal"
    :dayRevGoal="dayRevGoal"
    :dayRevGoalLeading="dayRevGoalLeading"
    :tomorrowSchedRevenue="tomorrowSchedRevenue"
    :activeLocationId="activeLocationId"
    :activeLocation="activeLocation"
    :activeMonthlyRevenueGoal="activeMonthlyRevenueGoal"
  ></stats-prod-tomorrow>

    <stats-prod-total-health
      v-if="activeLocation && activeLocation.openDentalStatus && activeLocation.openDentalStatus == 'active' && activeMonthlyRevenueGoal && (totalRevenue || totalRevenue==0) && (scheduledRevenue || scheduledRevenue==0)"
      :totalRevenue="totalRevenue"
      :scheduledRevenue="scheduledRevenue"
      :activeLocationId="activeLocationId"
      :activeLocation="activeLocation"
      :activeMonthlyRevenueGoal="activeMonthlyRevenueGoal"
    ></stats-prod-total-health>

  </div> <!-- /.col -->
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import ListErrors from '../components/ListErrors.vue'
import StatsProdPrevDay from '../components/StatsProdPrevDay.vue'
import StatsProdToday from '../components/StatsProdToday.vue'
import StatsProdTomorrow from '../components/StatsProdTomorrow.vue'
import StatsProdTotalHealth from '../components/StatsProdTotalHealth.vue'
import moment from 'moment'

export default {
  props: ['activeLocationId', 'activeLocation'],
  components: {
    ListErrors,
    StatsProdPrevDay,
    StatsProdToday,
    StatsProdTomorrow,
    StatsProdTotalHealth,
  },
  data () {
    return {
      errors: [],
      monthlyRevenueGoal: null, // bound to input when editing
      activeMonthlyRevenueGoal: null, // set when active location selected if it has one or after saving input
      yesterdayRevenue: null,
      totalRevenue: null,
      scheduledRevenue: null,
      todaySchedRevenue: null,
      tomorrowSchedRevenue: null,
      monthlyDays: null, // bound to input when editing
      activeMonthlyDays: null, // set when active location selected if it has one or after saving input
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),
    hasAdminPrivelege() {
      if(this.custom_claims.adminLevel == "superAdmin" || this.custom_claims.customerId == this.user.uid) {
        return true;
      }
      return false;
    },
    daysInMonth() {
      return moment().daysInMonth();
    },
    // daily goal calculations
      // these values cannot be rounded or accuracy is lost
    dayRevGoal() {
      if(this.activeMonthlyRevenueGoal ) {
        if(this.activeMonthlyDays) {
          return this.activeMonthlyRevenueGoal / this.activeMonthlyDays;
        } else {
          // if no monthly active days, fall back to days in month
          // return Math.round(this.activeMonthlyRevenueGoal / this.daysInMonth*10)/10;
          return this.activeMonthlyRevenueGoal / this.daysInMonth;
        }
      }
      return null;
    },
    dayRevGoalLeading() {
      if(this.activeMonthlyRevenueGoal ) {
        if(this.activeMonthlyDays) {
          return this.activeMonthlyRevenueGoal * 1.1 / this.activeMonthlyDays;
        } else {
          // if no monthly active days, fall back to days in month
          // return Math.round(this.activeMonthlyRevenueGoal / this.daysInMonth*10)/10;
          return this.activeMonthlyRevenueGoal * 1.1 / this.daysInMonth;
        }
      }
      return null;
    },
  },
  watch: {
    activeLocationId() {
      if(this.activeLocationId && this.activeLocation) {
        this.loadPracticeStats();
        if(this.activeLocation.monthlyRevenueGoal) {
          this.activeMonthlyRevenueGoal = this.activeLocation.monthlyRevenueGoal;
        } else {
          this.activeMonthlyRevenueGoal = null;
        }
        if(this.activeLocation.monthlyDays) {
          this.activeMonthlyDays = this.activeLocation.monthlyDays;
        } else {
          this.activeMonthlyDays = null;
        }
      }
    },
  },
  methods: {
    loadPracticeStats() {
      if(!this.custom_claims || !this.custom_claims.customerId) {
        return;
      }

      // load total_month_revenue on customers/{customerId}/stats/practice_health_goal/{locationId}
      Vue.firestore.doc(`customers/${this.custom_claims.customerId}/stats/practice_health_goal/${this.activeLocationId}/totals`)
      .get()
      .then(totalStatSnap => {
        if(totalStatSnap.exists) {
          let totalStats = totalStatSnap.data();
          this.totalRevenue = totalStats.total_month_revenue || 0;
          this.scheduledRevenue = totalStats.total_scheduled_revenue || null;
          this.todaySchedRevenue = totalStats.today_scheduled_revenue || 0;
          this.tomorrowSchedRevenue = totalStats.tomorrow_scheduled_revenue || 0;
        }
      })
      .catch(err => console.log(err));
    },
  },
  mounted () {
    
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

hr.text-divider {
  border-top-color: @dark-color;
  margin: 30px 0 10px;
}
.hr-chart {
  border-top: 3px solid @dark-color;
  margin: 50px 0 30px;
}

.subtitle {
  margin: 0 0 5px;
}
h3 {
  margin: 0 0 10px;
}
// set goal box
// ----------
.set-goal-box {
  background-color: fadeout(@color-blue, 50%);
  padding: 20px;

  .box-title {
    margin-bottom: 10px;
  }
  .form-control {
    margin: 0 10px 0 0;
    width: 100%;
  }
  .list-errors {
    margin-bottom: 0;
  }
}
// other
// ----------
.stat-block {
  margin-bottom: 30px;
}
</style>
