<template lang="html">
  <layout-public class="reset">
    <div>
      <div v-if="!this.resetEmailSent">
        <h1>Reset Password</h1>
        <input v-model="email" name="email" class="form-control" placeholder="Enter email" type="text" @keyup.enter="resetPassword"/>

        <list-errors :errors="errors"></list-errors>

        <p><button @click.prevent="resetPassword" class="btn btn-app">Send Reset Email</button></p>

        <hr class="hr-dark-color hr-bottom-short" />

        <p v-if="!this.user">Don't have an account?
          <span v-if="environment == 'production'"><a href="https://admin.hey-smiley.com/sign-up">Sign Up</a>. </span>
          <span v-if="environment == 'staging' || environment == 'development'"><a href="https://smiley-staging.web.app/sign-up">Sign Up</a>. </span>
          Or <router-link to="/log-in">Log In</router-link>.</p>
      </div>

      <div v-if="this.resetEmailSent">
        <h2>Password Reset Email Sent</h2>
        <p>Check your email for instructions on resetting your password.</p>
        <hr class="hr-dark-color hr-bottom-short" />
        <p v-if="!user"><router-link to="/log-in">Log in</router-link>.</p>
        <p v-if="user"><router-link to="/">back to account</router-link></p>
      </div>
    </div>
  </layout-public>
</template>

<script>
import {mapGetters} from 'vuex'
import LayoutPublic from '../components/LayoutPublic.vue'
import ListErrors from '../components/ListErrors.vue'

export default {
  props: [''],
  data () {
    return {
      email: '',
      resetEmailSent: false,
      errors: []
    }
  },
  components: {
    LayoutPublic, ListErrors
  },
  computed: {
    ...mapGetters(['user']),
    // set nextRoute to log in page
    nextRoute () {
      return '/log-in'
    },
    environment() {
      if(process.env.VUE_APP_ENV === "staging") {
        return "staging";
      }
      if(process.env.NODE_ENV === "production") {
        return "production";
      }
      if(process.env.NODE_ENV === "development" || process.env.VUE_APP_ENV === "test") {
        return "development";
      }
      return null;
    },
  },
  methods: {
    async resetPassword () {
      // reset errors
      this.errors = [];
      //  attempt logIn
      let result = await this.$firebase.resetPassword(this.email);
      // if result doesn't contain user, it's an error
      // console.log(result);
      if(result == true) {
        this.resetEmailSent = true;
      }
      else {
        if(result.code == "auth/invalid-email") {
          this.errors.push("Oops, that's not a valid email address.")
        }
        else if(result.code == "auth/user-not-found") {
          this.errors.push("Oops, there is no account using that email address.")
        }
        else {
          this.errors.push(result.message);
        }
      }
    },
  },
  mounted () {
    // if logged in, set email
    if(this.user) {
      this.email = this.user.email;
    }
  },
}
</script>

<style lang="less" scoped>
</style>
