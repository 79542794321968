<template>
  <div class="col-12">
    <div class="row">

      <div class="col-section col-12 text-center">
        <h2>Get in-person rewards the more Smileys you earn!</h2>
      </div> <!-- /.col -->

      <div class="col-section col-rewards-list col-12 col-md-6 col-lg-4">
        <div class="d-flex flex-row justify-content-between">
          <h3 class="snug">Reward</h3>
          <p class="snug">needed <img class="icon-smiley-points" src="../assets/img/icon-reward-smiley@2x.png" width="20px" height="20px" alt="" /></p>
        </div>
        <hr />
        
        <div>
          <div v-if="rewardsList && rewardsList.length" v-for="rew in rewardsListSorted" class="reward-item d-flex flex-row justify-content-between">
            <p class="snug">{{ rew.name }}</p>
            <p class="snug">{{ rew.points }}</p>
          </div>
          <p v-if="!rewardsList || !rewardsList.length" class="light-weight"><em>no rewards added</em></p>
        </div>

      </div> <!-- /.col -->

      <div class="col-section col-12 col-md-6 col-lg-4">
        <h3>How to Earn Smileys</h3>
        <hr />
        <ul class="list-unstyled">
          <li>5 <img class="icon-smiley-points" src="../assets/img/icon-reward-smiley@2x.png" width="20px" height="20px" alt="" /> &mdash; Post/Receive a High Five</li>
          <li>5 <img class="icon-smiley-points" src="../assets/img/icon-reward-smiley@2x.png" width="20px" height="20px" alt="" /> &mdash; Bump a message once a day</li>
          <li>10 <img class="icon-smiley-points" src="../assets/img/icon-reward-smiley@2x.png" width="20px" height="20px" alt="" /> &mdash; Stay caught up in Smiley once a day</li>
          <li>20 <img class="icon-smiley-points" src="../assets/img/icon-reward-smiley@2x.png" width="20px" height="20px" alt="" /> &mdash; Update your username to something other than your email address once</li>
          <li><em>More ways coming soon!</em></li>
        </ul>
      </div> <!-- /.col -->
    
    </div> <!-- /.row -->
  </div> <!-- /.col -->
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import ListErrors from '../components/ListErrors.vue'

export default {
props: ['customer'],
components: {
  ListErrors
},
data () {
  return {
    errors: [],
    loading: false,
    editingRewards: false,
    rewardsList: [],
    success: false,
  }
},
computed: {
  ...mapGetters(['user', 'user_props', 'custom_claims']),
  rewardsListSorted() {
    return this.rewardsList.sort((prev, next) => next.points - prev.points);
  },
},
watch: {
  custom_claims () {
    this.getCustomerObject();
  },
},
methods: {
  getCustomerObject() {
    if(this.custom_claims && this.custom_claims.customerId) {
      Vue.firestore.doc(`customers/${this.custom_claims.customerId}`)
      .get()
      .then(custSnap => {
        // console.log(custSnap.data());
        if(custSnap.exists && custSnap.data().rewards_list) {
          this.rewardsList = custSnap.data().rewards_list;
        }
      })
    }
  },
},
mounted () {
  this.getCustomerObject();
},
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

h2 {
  line-height: 1.3em;
}
h2,h3,p {
  margin: 0 0 20px;
}
hr {
  border-top: 1px solid @dark-color;
  margin: 20px 0;
}
.icon-smiley-points {
  vertical-align: top;
}
ul.list-unstyled li {
  margin-bottom: 20px;
}
.reward-item {
  margin-bottom: 20px;
}
.col-section {
  margin-bottom: 30px;
}
</style>
