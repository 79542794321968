/* eslint-disable no-console */

import { register } from 'register-service-worker'
import store from './store'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready (registration) {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered (registration) {
      console.log('Service worker has been registered.')
    },
    cached (registration) {
      console.log('Content has been cached for offline use.')
    },
    async updatefound (registration) {
      console.log('New content is downloading.')

    },
    async updated (registration) {
      console.log('New content is available; please refresh.')
      console.log('registration',registration);
      // only show update banner if there is a waiting registration
      if(registration.waiting) {
        // this will show a loading notice instead of a banner that requires a tap
        store.dispatch('updatedServiceWorker', true);
        // bypass banner and just install immediately
        // postMessage style for built-in listener
          // sending skip waiting causes immediate controller change, firing listener below that refreshes page
        registration.waiting.postMessage({type: 'SKIP_WAITING'});
      }
    },
    offline (registration) {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}

// service worker update handlers
let refreshing = false;

if ('serviceWorker' in navigator) {
  // wait for window to load
  window.addEventListener('load', async () => {
    // console.log('adding controllerchange listener')
    // detect controller change and refresh the page
      // fires after skip waiting sent then the message handler in public/service-worker.js is called
    navigator.serviceWorker.addEventListener('controllerchange', async (event) => {
      console.log('controllerchange detected');
      // refresh page to apply SW update
        // only if not refreshing
      if (!refreshing) {
          window.location.reload();
          refreshing = true;
      }
    });
    // claim all windows to avoid needless refreshing
      // without this, any link opened in new window causes service worker update and entire app to reload
      // allows logging in with google
      // allows opening message attachments in new window
    navigator.serviceWorker.addEventListener('activate', event => {
      console.log('activate>clients.claim')
      event.waitUntil(clients.claim());
    });
  });
}
