<template lang="html">
  <layout-public class="log-in">
    <div>
      <h1>How to Join Smiley</h1>

      <p>To join your team on Smiley, <strong>ask your team lead or your team's Smiley account admin for an invite</strong>.</p>

      <p>You can only join your team's account when invited. If you sign up without an invite, you won't be on the same team! Once invited, your email invitation will show you how to create your login and password.</p>

      <p>See you in Smiley soon!</p>

      <p><router-link to="/log-in"><i class="far fa-fw fa-angle-left"></i>Go back to Log In</router-link></p>

    </div>
  </layout-public>
</template>

<script>
import {mapGetters} from 'vuex'
import LayoutPublic from '../components/LayoutPublic.vue'

export default {
  data () {
    return {

    }
  },
  components: {
    LayoutPublic
  },
  computed: {
    ...mapGetters(['user']),
    // get next route from querystring, set in FirebaseApp.js, or default to root
    nextRoute () {
      return this.$route.query.redirect || '/'
    },
  },
  watch: {
    //  when signUp completes, go to requested page
    user (auth) {
      if(auth) {
        this.$router.replace(this.nextRoute)
      }
    }
  },
  mounted () {
    // on page refresh, if user, go to requested page
    if(this.user) {
      this.$router.replace(this.nextRoute)
    }
  },
}
</script>

<style lang="less" scoped>
</style>
