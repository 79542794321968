<template lang="html">
  <div v-show="messages" class="catch-up">
    <div @click="close" class="catch-up-container">

      <transition-group :name="swipingLeft ? 'swipeleft' : 'swiperight'" tag="div">
        <message-onboarding
          v-for="(message,index) in messages"
          v-if="index == currentOnboardingMessageIndex"
          :message="message"
          :key="message.id"
          class="message"
        ></message-onboarding>
      </transition-group>

      <div v-if="messages && messages.length" class="controls">
        <div class="controls-mid">
          <span v-if="currentOnboardingMessageIndex > 0" class="arr arr-left"></span>
          <span v-if="currentOnboardingMessageIndex < messages.length-1" class="arr arr-right"></span>
        </div>
        <div class="controls-footer">
          <p v-if="messages && messages.length && messages.length > 11">...</p>
          <p v-if="messages && messages.length && messages.length <= 11" class="progress-container">
            <span
              v-for="(message, index) in messages"
              :key="index"
              class="progress-circle"
              :class="[currentOnboardingMessageIndex == index ? 'progress-circle-filled' : '']"
            ></span>
          </p>
        </div>
      </div> <!-- /.controls -->

    </div> <!-- /.catch-up-container -->
  </div> <!-- /.catch-up -->
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import ListErrors from '../components/ListErrors.vue'
import MessageOnboarding from '../components/MessageOnboarding.vue'
import Firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

export default {
  components: {
    ListErrors, MessageOnboarding
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),
  },
  data () {
    return {
      currentOnboardingMessageIndex: null,
      swipeXStart: null,
      swipingLeft: null, // used to switch between 2 css transitions for transition-group
      messages: [
        {
          id: 'slide1',
          custom_title: 'Welcome to Smiley!',
          messageText: 'In Smiley, your team can share quick messages to stay connected, encourage one another, and keep everyone up to date. Your unread messages will open automatically when you open Smiley. <strong>Swipe left to continue.</strong>',
          post_type: 'smiley',
        },
        {
          id: 'slide2',
          custom_title: 'This is a High Five!',
          messageText: 'Inside a message channel, you can send other team members a high five anytime to recognize their hard work or send some encouragement. Everyone in the channel will be able to see it and celebrate with you!',
          post_type: 'high_five',
        },
        {
          id: 'slide3',
          custom_title: 'This is a Head’s Up!',
          messageText: 'Team members who have super admin or group admin privileges can post a head’s up to everyone in a channel, or to everyone in your entire Smiley account. These messages contain useful info everyone needs to know.',
          post_type: 'heads_up',
        },
        {
          id: 'slide4',
          custom_title: 'Adding Images & Links to a Message',
          messageText: 'Attach an image and/or URL when sharing a High Five or Head’s Up. Just tap the button for each.',
          post_type: 'custom_image',
          custom_image: 'onboarding-photo-image-link@2x.png',
        },
        {
          id: 'slide5',
          custom_title: 'This is a Google Review!',
          messageText: 'If your team has connected Google My Business, you’ll receive a message when a patient posts a Google Review. Positive reviews are a nice reminder that we’re all part of something bigger and making a difference for our patients.',
          post_type: 'google_review',
        },
        {
          id: 'slide6',
          custom_title: 'Keep Smiling!',
          messageText: 'That’s all you need to know.  <strong>Tap anywhere to close</strong>, see the rest of Smiley, and starting sharing messages with your team.',
          post_type: 'smiley',
        },
      ],
    }
  },
  watch: {
    messages(newMessages, oldMessages) {
      // on router navigation
      if(newMessages) {
        this.currentOnboardingMessageIndex = 0;
      }
    },
    currentOnboardingMessageIndex(newIndex,oldIndex) {
      // if viewing last onboarding message, set user_props.
      if(newIndex == this.messages.length-1) {
        Vue.firestore.doc(`users/${this.user.uid}`)
          .update({
            onboarding_complete: true,
          })
          .then(() => {
            console.log(`set onboarding_complete to true`)
          })
          .catch(err => console.log(err));
      }
    },
  },
  methods: {
    close() {
      if(this.currentOnboardingMessageIndex == this.messages.length-1) {
        this.$emit('close-onboarding');
      }
    },
    handleTouchStart(event) {
      // console.log('handleTouchStart',event);
      this.swipeXStart = event.touches[0].clientX;
    },
    handleTouchMove(event) {
      if(!this.swipeXStart) {
        return;
      }
      // console.log('handleTouchMove',event);
      const swipeXEnd = event.touches[0].clientX;
      const diff = this.swipeXStart - swipeXEnd;
      // if user swiped more than 50px
      if(Math.abs(diff) > 50) {
        if(swipeXEnd < this.swipeXStart) {
          // console.log('swipe left')
          // don't swipe left at end of array
          if(this.currentOnboardingMessageIndex < this.messages.length-1) {
            this.swipingLeft = true;
            this.currentOnboardingMessageIndex++;
          }
        } else {
          // console.log('swipe right')
          // don't swipe right at beginning of array
          if(this.currentOnboardingMessageIndex >= 1) {
            this.swipingLeft = false;
            this.currentOnboardingMessageIndex--;
          }
        }
        this.swipeXStart = null;
      }

    },
  },
  mounted() {
    document.addEventListener('touchstart', this.handleTouchStart, false);
    // listener for touchend doesn't work, so use touchmove instead
    document.addEventListener('touchmove', this.handleTouchMove, false);

    // on page refresh
    if(this.messages) {
      this.currentOnboardingMessageIndex = 0;
    }

  },
  // prevent duplicate listeners when navigating away and then back
  beforeDestroy() {
    document.removeEventListener('touchstart', this.handleTouchStart, false);
    document.removeEventListener('touchmove', this.handleTouchMove, false);
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.catch-up {
  background-color: @light-color;
  height: 100%;
  width: 100%;
}
.catch-up-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  // select div created by transition-group and add padding
  div:first-child {

    height: 100%;
    // overflow-y: scroll;
  }
}
.message {
  width: 100%;
  min-width: 100%;
  height: 100%;
  // height: 75%;
  padding-bottom: 100px; // always keep space for controls-footer
}
.controls-footer {
  position: absolute;
  left: 0;
  bottom: 30px;
  width: 100%;
  z-index: 4100;
  text-align: center;

  p {
    margin: 0 0 10px;
  }
}
.controls-mid {
  .arr {
    display: block;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    margin-top: -15px;
    z-index: 4100;
  }
  .arr.arr-left {
    left: 30px;
    border-left: 2px solid @light-color;
    border-bottom: 2px solid @light-color;
  }
  .arr.arr-right {
    right: 30px;
    border-top: 2px solid @light-color;
    border-right: 2px solid @light-color;
  }
}
.progress-container {
  margin: 0;

  .progress-circle {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    border: 2px solid @dark-color;
    background-color: transparent;
    margin-right: 15px;
    position: relative;
  }
  .progress-circle-filled {
    background-color: @dark-color;
  }
  .progress-circle:last-child {
    margin: 0;
  }
  .progress-circle .fa-check {
    position: absolute;
    left: 0;
    top: -4px;
  }
  .progress-circle-filled .fa-check {
    color: @light-color;
  }
}
</style>
